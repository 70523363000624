import React from 'react';
import { CircularProgress } from '@material-ui/core';

const Loading = () => {

    return (
        <div
            style={{
                width: '100%',
                marginTop: '10em',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <CircularProgress color="secondary" />
        </div>
    )
};

export default Loading;