import React, { useEffect, useState } from 'react';
import {
    Table,
    TableContainer,
    TableCell,
    TableBody,
    Paper,
    TableHead,
    TableRow
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { prettyTimestamp } from '../../../utils/dateFormatter';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

const AnsweredSurveysList = ({
    getAnsweredSurveysList,
    answeredSurveys,
    history
}) => {
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        getAnsweredSurveysList()
            .then((response) => {
                setLoading(false);
                return response;
            })
            .catch(err => {
                console.log('err: ',err);
                return err;
            });
    }, []);

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell><strong>Encuesta</strong></TableCell>
                        <TableCell><strong>Sucursal</strong></TableCell>
                        <TableCell><strong>Cliente</strong></TableCell>
                        <TableCell><strong>Fecha</strong></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {answeredSurveys.map(survey => (
                        <TableRow
                            hover
                            key={survey.id}
                            onClick={() => {
                                history.push(`/surveys/${survey.id}`)
                            }}
                        >
                            <TableCell component="th" scope="row">
                                {survey.luSurvey.name}</TableCell>
                            <TableCell>{survey.branch.name}</TableCell>
                            <TableCell>{survey.customer.name}</TableCell>
                            <TableCell>{prettyTimestamp(survey.createdDate)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default AnsweredSurveysList;