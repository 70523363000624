import api from '../index';
import { fullDate, startOf, endOf } from '../../utils/dateFormatter';

class Report {
    static getDaily(startDate, endDate) {
        return new Promise((resolve, reject) => {
            api.post('/reports/daily', { startDate, endDate })
                .then(response => resolve(response.data))
                .catch(err => reject(err));
        })
    }

    static getBranch(startDate, endDate, branchesId) {
        return new Promise((resolve, reject) => {
            api.post('/reports/branch', { startDate, endDate, branchesId })
                .then(response => resolve(response.data))
                .catch(err => reject(err));
        })
    }

    static getSales(startDate, endDate) {
        return new Promise((resolve, reject) => {
            api.post('/reports/sales', { startDate, endDate })
                .then(response => resolve(response.data))
                .catch(err => reject(err));
        })
    }

    static getGoalsPerformance(startDate, endDate) {
        return new Promise((resolve, reject) => {
            api.get('/reports/goals-performance')
                .then(response => resolve(response.data))
                .catch(err => reject(err));
        })
    }

    static getSalesPeformanceComparison(startDate, endDate, branchId = null) {
        return new Promise((resolve, reject) => {
            api.get(`/sales/performance/date/${startDate}/${endDate}`)
                .then(response => resolve(response.data))
                .catch(err => reject(err));
        });
    }
}

export default Report;
