import React, { FC } from 'react';

const Holder = ({ children }) => (
    <div className="w-full flex flex-col p-3 px-4 bg-white rounded-lg shadow-sm mb-3 items-center justify-between">
        <React.Fragment>
            {children}
        </React.Fragment>
    </div>
);

export default Holder;