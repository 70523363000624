import React from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';

const SelectInput = ({ id, value, handleChange, label, options }) => {
    const onChange = (e) => {
        console.log('e.target: ',e.target.value);
        handleChange(e.target.value);
    }
    return (
        <FormControl variant="outlined">
            <InputLabel id={id}>{label}</InputLabel>
            <Select
                labelId={`${id}-label`}
                id={id}
                value={value}
                onChange={onChange}
                label={label}
                style={{ minWidth: '15em'}}
            >
                {options.map(option => (
                    <MenuItem
                        key={option.id}
                        selected={option.id === value}
                        value={option.id}
                    >
                        {option.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
};

export default SelectInput;