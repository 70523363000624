import api from '../index';

class Transactions {
    static getCategories() {
        return new Promise((resolve, reject) => {
            api.get('/transactions/categories')
                .then(response => resolve(response.data))
                .catch(err => reject(err));
        })
    }

    static createTxn(account, concept, category, channel, type, subtotal, tax, total) {
        return new Promise((resolve, reject) => {
            api.post('/transactions',
                { account, concept, category, channel, type, subtotal, tax, total, domainId:2 }
            )
                .then(response => resolve(response.data))
                .catch(err => reject(err));
        })
    }

    static getBankAccounts() {
        return new Promise((resolve, reject) => {
            api.get('/bank-accounts')
                .then(response => resolve(response))
                .catch(err => reject(err));
        });
    }

    static getTxns(businessEntities, bankAccounts, type = 0) {
        const fixedBE = businessEntities.length > 0 ? JSON.stringify(businessEntities) : null;
        const fixedBA = bankAccounts.length > 0 ? JSON.stringify(bankAccounts) : null;
        let params = `?type=${type}`;
        if (fixedBE) params += `&businessEntities=${fixedBE}`;
        if (fixedBA) params += `&bankAccounts=${fixedBA}`;

        return new Promise((resolve, reject) => {
            api.get(`/transactions${params}`)
                .then(response => resolve(response.data))
                .catch(err => reject(err));
        });
    }
}

export default Transactions;
