import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Button
} from '@material-ui/core';
import Header from '../common/header/index.header';
import Loading from '../common/loading';
import { prettyMoney } from '../../utils/helpers';
import { prettyMonth } from '../../utils/dateFormatter';
import { checkMonth, setActiveBranch } from '../../ducks/goals.ducks';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

const Goals = ({
    goalsCheckMonth,
    goalsSetActiveBranch,
    branches,
    complete,
    activeMonth,
    history
}) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [activeFullMonth, setActiveFullMonth] = useState(activeMonth);
    useEffect(() => {
        goalsCheckMonth()
            .then(() => setLoading(false));
    }, []);
    useEffect(() => {
        setActiveFullMonth(activeMonth);
    }, [activeMonth]);
    const handleSelect = (branchId, branchName, activeMonth) => {
        goalsSetActiveBranch(branchId, branchName, activeMonth);
        history.push('/goals/create');
    }
    if (loading) return <Loading />
    return (
        <div>
            <Header
                title={`Metas de ${prettyMonth(activeMonth)}`}
                subtitle={complete ? 'Monitorea las metas de tus sucursales' : '¡Todavia no has agregado todas las metas de este mes!'}
            />
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Sucursal</strong></TableCell>
                            <TableCell><strong>Monto</strong></TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {branches.map((branch) => (
                        <TableRow key={branch.id}>
                            <TableCell component="th" scope="row">
                                {branch.branchName}
                            </TableCell>
                            <TableCell>{prettyMoney(branch.meta)}</TableCell>
                            <TableCell align="right">
                                {!branch.meta && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleSelect(branch.id, branch.branchName, activeFullMonth)}
                                    >
                                        Agregar
                                    </Button>
                                )} 
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
};

const mapStateToProps = ({ goals: { missingBranches, branches, complete, activeMonth} }) => ({
    missingBranches,
    branches,
    complete,
    activeMonth
});

const mapDispatchToProps = dispatch => ({
    goalsCheckMonth: month => dispatch(checkMonth(month)),
    goalsSetActiveBranch: (branchId, branchName, targetDate) =>
        dispatch(setActiveBranch(branchId, branchName, targetDate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Goals);