import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination
} from '@material-ui/core';
import Holder from '../../common/holder';
import { prettyDate } from '../../../utils/dateFormatter';
import { prettyMoney } from '../../../utils/helpers';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

const Days = ({
    dates,
    history
}) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    return (
        <div className="mr-2">
            <Holder>
                <Link className="self-end" to="/reports/day">Detalles</Link>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell><p className="text-lg font-semibold">Fecha</p></TableCell>
                                <TableCell align="right"><p className="text-lg font-semibold">Total</p></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {stableSort(dates, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => (
                                    <TableRow hover key={row.id} onClick={() => history.push(`/reports/day?startDate=${row.cwDate}&endDate=${row.cwDate}`)}>
                                        <TableCell component="th" scope="row">
                                            {prettyDate(row.cwDate)}
                                        </TableCell>
                                        <TableCell align="right">{prettyMoney(row.cwAmt)}</TableCell>
                                    </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    className="self-end"
                    rowsPerPageOptions={[5, 10, 25]}
                    count={dates.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />                   
            </Holder>
        </div>
        
    )
};

export default Days;