import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    Button,
    CircularProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createGoal } from '../../ducks/goals.ducks';
import { prettyMonth } from '../../utils/dateFormatter';


const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(1),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: '25ch',
    },
}));

const CreateGoal = ({
    goalCreate,
    branchId,
    branchName,
    targetDate,
    history
}) => {
    const classes = useStyles();
    const [amount, setAmount] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!branchId && !targetDate) return history.push('/goals');
    }, [branchId, targetDate]);

    const handleSubmit = () => {
        if(amount && branchId && targetDate) {
            setLoading(true);
            goalCreate(amount)
                .then(res=> {
                    setLoading(false);
                    //redirect
                    history.push('/goals');
                }).catch(err => {
                    setLoading(false);
                    // handle error
                });
        }
    };
    return (
        <div style={{ width: '80%', margin:'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div style={{ marginBottom: '2em' }}>
                <Link to ="/goals">Regresar</Link>

            </div>
            <div style={{ marginBottom: '2em' }}>
                <h3>Agregar meta para <strong>{branchName}</strong></h3>
                <h4>Para <strong>{prettyMonth(targetDate)}</strong></h4>
            </div>
            <div style={{ marginBottom: '2em' }}>
                <FormControl fullWidth className={classes.margin} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-amount"
                        type="number"
                        placeholder="0.00"
                        value={amount}
                        onChange={(event) => {
                            setAmount(event.target.value)
                        }}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        labelWidth={60}
                    />
                </FormControl>
            </div>
            <Button
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={handleSubmit}
            >
                {loading ?
                    <CircularProgress size={20} color="white" />
                    : 'Capturar'
                }
            </Button>
        </div>
    )
};

const mapStateToProps = ({ goals: { create }}) => ({
    branchId: create.branchId,
    targetDate: create.targetDate,
    branchName: create.branchName
});

const mapDispatchToProps = dispatch => ({
    goalCreate: (amount) => dispatch(createGoal(amount)) 
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateGoal);