import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import {
    Paper,
    Tabs,
    Tab,
    Box,
    Typography
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { loadSettings, createBranch, createBusinessEntity, createUser, createBankAccount } from '../../ducks/users.ducks';

import Header from '../common/header/index.header';
import Account from './account.settings';
import List from './list.settings';
const LU_BANKS = [
    { id: 1, value: 1, name: 'BBVA' },
    { id: 2, value: 2, name: 'SANTADER' },
    { id: 3, value: 3, name: 'BANBAJIO' },
    { id: 4, value: 4, name: 'AMEX' },
];

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
    >
        {value === index && (
        <Box p={3}>
            <span>{children}</span>
        </Box>
        )}
    </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: '100%',
    },
}));

const Settings = ({
    history,
    me,
    branches,
    initSettings,
    bankAccounts,
    businessEntities,
    paymentMethods,
    employees,
    createSettingsUser,
    createSettingsBankAccount,
    createSettingsEntity,
    createSettingsBranch
}) => {
    const classes = useStyles();
    const theme = useTheme();

    const [loading,setLoading] = useState(true);
    const [active, setActive] = useState(0);

    useEffect(() => {   
        initSettings()
            .then(() => setLoading(false));
    }, []);

    const handleChange = (e, value) => {
        setActive(value);
    }

    return (
        <>
            <Header title="Ajustes" subtitle="Revisa los detalles de tu cuenta" />
            <Paper className={classes.root}>
                <Tabs
                    value={active}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Cuenta" />
                    <Tab label="Empleados" />
                    <Tab label="Bancos" />
                    <Tab label="Sucursales" />
                    <Tab label="Razones Sociales" />
                    <Tab label="Métodos de Pago" />
                </Tabs>
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={active}
                    onChangeIndex={handleChange}
                >
                    <TabPanel value={active} index={0} dir={theme.direction}>
                        <Account me={me} />
                    </TabPanel>
                    <TabPanel value={active} index={1} dir={theme.direction}>
                        <List
                            items={employees}
                            panel={[
                                { label: 'Nombre', field: 'name' },
                                { label: 'Correo', field: 'email' },
                                { label: 'Teléfono', field: 'phone' },
                                { label: 'Alias', field: 'alias' },
                                {
                                    label: 'Sucursal',
                                    field: 'branch',
                                    type: 'select',
                                    inputField: 'branchId',
                                    options: branches
                                },
                            ]}
                            handleSubmit={createSettingsUser}
                        />
                        
                    </TabPanel>
                    <TabPanel value={active} index={2} dir={theme.direction}>
                        <List
                            items={bankAccounts}
                            panel={[
                                {
                                    label: 'Banco',
                                    field: 'bankName',
                                    inputField: 'bankId',
                                    type: 'select',
                                    options: LU_BANKS
                                },
                                {
                                    label: 'Número de cuenta',
                                    field: 'accountNumber',
                                    inputField: '',
                                },
                                {
                                    label: 'Número de cliente',
                                    field: 'clientNumber',
                                },
                                {
                                    label: 'Razón social',
                                    field: 'businessEntity',
                                    inputField: 'businessEntityId',
                                    type: 'select',
                                    options: businessEntities
                                },
                                {
                                    label: 'Alias',
                                    field: 'name',
                                }
                            ]}
                            handleSubmit={createSettingsBankAccount}
                        />  
                    </TabPanel>
                    <TabPanel value={active} index={3} dir={theme.direction}>
                        <List
                            items={branches}
                            panel={[
                                {
                                    label: 'Razón Social',
                                    field: 'businessEntity',
                                    inputField: 'businessEntityId',
                                    options: businessEntities,
                                    type: 'select'
                                },
                                {
                                    label: 'Nombre',
                                    field: 'name',
                                },
                            ]}
                            handleSubmit={createSettingsBranch}
                        />
                    </TabPanel>

                    <TabPanel value={active} index={4} dir={theme.direction}>
                        <List
                            items={businessEntities}
                            panel={[
                                {
                                    label: 'Nombre',
                                    field: 'name',
                                },
                            ]}
                            handleSubmit={createSettingsEntity}
                        />
                    </TabPanel>
                    <TabPanel value={active} index={5} dir={theme.direction}>
                        <List
                            items={paymentMethods}
                            panel={[
                                {
                                    label: 'Nombre',
                                    field: 'name',
                                },
                                {
                                    label: 'Descripción',
                                    field: 'description',
                                },

                                {
                                    label: 'Proveedor',
                                    field: 'provider',
                                },
                            ]}
                            handleSubmit={createSettingsEntity}
                        />
                    </TabPanel>
                </SwipeableViews>
            </Paper>
        </>
    )
};

export const mapStateToProps = ({ users }) => ({
    me: users.me,
    bankAccounts: users.bankAccounts,
    businessEntities: users.businessEntities,
    branches: users.branches,
    employees: users.employees,
    paymentMethods: users.paymentMethods
});

export const mapDispatchToProps = dispatch => ({
    initSettings: () => dispatch(loadSettings()),
    createSettingsEntity: ({ name }) => dispatch(createBusinessEntity({ name })),
    createSettingsBranch: ({ name, businessEntityId }) => dispatch(createBranch({name, businessEntityId})),
    createSettingsUser: ({ name, email, phone, alias, branchId }) => dispatch(createUser({ name, email, phone, alias, branchId })),
    createSettingsBankAccount: (name, accountNumber, clientNumber, bankId, businessEntityId) =>
        dispatch(createBankAccount(name, accountNumber, clientNumber, bankId, businessEntityId))
});

Settings.propTypes = {
    me: PropTypes.shape(),
    branches: PropTypes.arrayOf(Object),
    bankAccounts: PropTypes.arrayOf(Object),
    businessEntities: PropTypes.arrayOf(Object),
    initSettings: PropTypes.func,
    createSettingsEntity: PropTypes.func,
    createSettingsBranch: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);