import { surveys as initialState } from '../config/initialState';
import Surveys from '../api/services/Surveys';

const CUSTOMER_SURVEY = [
    {
        type: 'CUSTOMER',
        questionTypeId: 4,
        question: 'Nombre',
        answer: null,
        isRequired: true,
        id: 'name'
    },
    {
        type: 'CUSTOMER',
        questionTypeId: 5,
        question: 'Teléfono',
        answer: null,
        isRequired: true,
        id: 'phone'
    },
    {
        type: 'CUSTOMER',
        questionTypeId: 6,
        question: 'Correo',
        answer: null,
        isRequired: false,
        id: 'email'
    }
];

export const SET_SURVEY_CLIENT = '[SURVEYS] SET_SURVEY_CLIENT';
export const SET_PERFORMANCE_SURVEYS = '[SURVEYS] SET_PERFORMANCE_SURVEYS';
export const SET_ANSWERED_SURVEYS = '[SURVEYS] SET_ANSWERED_SURVEYS';
export const SET_ANSWERED_SURVEY = '[SURVEYS] SET_ANSWERED_SURVEY';

const setSurveyClient = ({ survey, surveyName }) =>
    ({ type: SET_SURVEY_CLIENT, questions: survey, surveyName });

const setPerformanceSurveys = ({ questionsPerformance, branches }) => ({ type: SET_PERFORMANCE_SURVEYS, branches, questionsPerformance });
const setAnsweredSurveys = (answeredSurveys) => ({ type: SET_ANSWERED_SURVEYS, answeredSurveys });
export const setAnsweredSurvey = ({ answeredSurvey }) => ({ type: SET_ANSWERED_SURVEY, answeredSurvey });

export const getSurveyClient = (surveyId, branchId) => dispatch => (
    new Promise((resolve, reject) => {
        Surveys.getSurveyClient(surveyId, branchId)
            .then(({ data: { survey, surveyName } }) => {
                survey.unshift(...CUSTOMER_SURVEY);
                dispatch(setSurveyClient({ survey, surveyName }))
                return resolve(survey);
            }).catch(err => console.log('err: ',err));
    })
);

export const getSurveysPerformance = () => dispatch => (
    new Promise((resolve, rejectt) => {
        Surveys.getSurveysPerformance()
            .then(({ data }) => {
                console.log('date: ',data);
                dispatch(setPerformanceSurveys(data));
                return resolve(data);
            })
            .catch(err => console.log('errR: ',err))
    })
)

export const createSurveyCustomer = (answers, branchId, surveyId) => dispatch => (
    new Promise(async (resolve, reject) => {
        const name = answers.find(answer => answer.id === 'name').answer;
        const email = answers.find(answer => answer.id === 'email').answer;
        const phone = answers.find(answer => answer.id === 'phone').answer;
        const fixedAnswers = answers.filter(answer => answer.type !== 'CUSTOMER');
        const surveyCustomer = await Surveys.createSurveyCustomer({
            answers: fixedAnswers,
            customer: {
                name,
                email,
                phone
            },
            branchId: Number(branchId),
            luSurveyId: Number(surveyId)
        });
        return resolve(surveyCustomer)
    })
);

export const getAnsweredSurveys = () => dispatch => (
    new Promise(async(resolve, reject) => {
        const answeredSurveys = await Surveys.getAnsweredSurveys();
        dispatch(setAnsweredSurveys(answeredSurveys.data.surveys));
        return resolve(answeredSurveys.data.surveys);
    })
);

export const getAnsweredSurveyById = (answeredSurveyId) => dispatch => (
    new Promise(async(resolve, reject) => {
        const answeredSurvey = await Surveys.getAnsweredSurveyById(answeredSurveyId);
        dispatch(setAnsweredSurvey(answeredSurvey.data));
        return resolve(answeredSurvey.data)
    })
)

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SURVEY_CLIENT:
            return {
                ...state,
                surveyClient: {
                    questions: action.questions,
                    surveyName: action.surveyName
                }
            };
        case SET_PERFORMANCE_SURVEYS:
            return {
                ...state,
                branches: action.branches,
                questionsPerformance: action.questionsPerformance
            }
        case SET_ANSWERED_SURVEYS:
            return {
                ...state,
                answeredSurveys: action.answeredSurveys
            }
        case SET_ANSWERED_SURVEY:
            return {
                ...state,
                answeredSurvey: {
                    id: action.answeredSurvey.id,
                    createdDate: action.answeredSurvey.createdDate,
                    surveyAnswers: action.answeredSurvey.surveyAnswers,
                    luSurvey: action.answeredSurvey.luSurvey,
                    branch: action.answeredSurvey.branch,
                    customer: action.answeredSurvey.customer,
                }
            };
        default: return state;
    }
};
