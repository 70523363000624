import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    FormControl,
    InputAdornment,
    Button,
    CircularProgress,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import { getCategories, createTxn } from '../../ducks/transactions.ducks';
import { getBankAccounts } from '../../ducks/users.ducks';
import { TRANSACTION_TYPES, TRANSACTION_CATEGORIES, TRANSACTION_CHANNELS } from '../../utils/constants';
import SelectInput from '../common/inputs/select.input';
import NumberInput from '../common/inputs/number.input';
import TextInput from '../common/inputs/text.input';
import Header from '../common/header/index.header';

import { prettyMoney } from '../../utils/helpers';

const TAX = 0.16;

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(1),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      width: '25ch',
    },
}));

const CreateTransactionForm = ({
    createTxnForm,
    getTxnsCategories,
    getTxnsBankAccounts,
    bankAccounts,
    history,
    categories,
}) => {

    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [account, setAccount] = useState(null);
    const [concept, setConcept] = useState(null);
    const [category, setCategory] = useState(null);
    const [channel, setChannel] = useState(null);   
    const [type, setType] = useState(null);
    const [subtotal, setSubtotal] = useState(null);
    const [hasTax, setHasTax] = useState(false);
    const [calculated, setCalculated] = useState({ tax: 0, total: 0});

    useEffect(() => {
        getTxnsBankAccounts();
        // getTxnsCategories();
    }, [getTxnsCategories, getTxnsBankAccounts]);

    const handleSubmit = () => {
        setLoading(true);
        createTxnForm(
            account, concept, category, channel, type, subtotal, calculated.tax, calculated.total
        )
            .then(() => {
                history.push('/transactions/list')
                setLoading(false)
            });
    }

    const calculateTax = (amount, tax) => {
        setCalculated({
            tax: tax ? (amount * TAX) : 0.00,
            total: tax ? (amount * (TAX + 1)) : (amount)
        });
    };

    return (
        <div>
            <FormControl fullWidth className={classes.margin} variant="outlined">
                <Header
                    title="Crear una transacción"
                    subtitle="Agregar ingreso o egreso"
                    history={history}
                    enableBack
                />
                <SelectInput
                    id="demo-simple-select-outlined-label"
                    label="Cuenta"
                    handleChange={(value) => setAccount(value)}
                    value={account}
                    options={bankAccounts}
                />

                <SelectInput
                    id="demo-simple-select-outlined-label"
                    label="Categoria"
                    handleChange={(value) => setCategory(value)}
                    value={category}
                    options={TRANSACTION_CATEGORIES}
                />

                <SelectInput
                    id="demo-simple-select-outlined-label"
                    label="Canal"
                    handleChange={(value) => setChannel(value)}
                    value={channel}
                    options={TRANSACTION_CHANNELS}
                />

                <TextInput
                    id="outlined-basic"
                    label="Concepto"
                    value={concept}
                    handleChange={(value) => setConcept(value)}
                />

                <SelectInput
                    id="demo-simple-select-outlined-label"
                    label="Tipo"
                    handleChange={(value) => {
                        console.log('value ',value);
                        setType(value)
                    }}
                    value={type}
                    options={TRANSACTION_TYPES}
                />

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-betweenn', width: '100%' }}>
                    <div style={{ width: '90%', marginRight: '1em   ' }}>
                        <NumberInput
                            id="outlined-adornment-amount"
                            label="Subtotal"
                            placeholder="0.00"
                            value={subtotal}
                            handleChange={(value) => {
                                setSubtotal(value);
                                calculateTax(value, hasTax);
                            }}
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            disabled={false}
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div style={{ width: '10%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={hasTax}
                                        onChange={({ target }) => {
                                            setHasTax(target.checked);
                                            calculateTax(subtotal, target.checked);
                                        }}
                                        name="IVA"
                                        color="primary"
                                    />
                                }
                                label="IVA"
                            />
                    </div>

                </div>

                

                <NumberInput
                    id="outlined-adornment-amount"
                    label="IVA"
                    placeholder="0.00"
                    value={calculated.tax}
                    handleChange={() => null}
                    disabled
                />

                <NumberInput
                    id="outlined-adornment-amount"
                    label="Total"
                    placeholder="0.00"
                    value={calculated.total}
                    handleChange={() => null}
                    disabled
                />

                <Button
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    onClick={handleSubmit}
                >
                    {loading ?
                        <CircularProgress size={20} color="white" />
                        : 'Capturar'
                    }
                </Button>
            </FormControl>
        </div>
    )
};

const mapStateToProps = ({
    transactions: { categories },
    users: { bankAccounts }
}) => ({
    categories,
    bankAccounts,
});

const mapDispatchToProps = dispatch => ({
    getTxnsCategories: () => dispatch(getCategories()),
    getTxnsBankAccounts: () => dispatch(getBankAccounts()),
    createTxnForm: (account, concept, category, channel, type, subtotal, tax, total) =>
        dispatch(createTxn(account, concept, category, channel, type, subtotal, tax, total))
})

CreateTransactionForm.propTypes = {
    getTxnsCategories: PropTypes.func,
    getTxnsBankAccounts: PropTypes.func,
    categories: PropTypes.arrayOf(Object),
    bankAccounts: PropTypes.arrayOf(Object)
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTransactionForm);
