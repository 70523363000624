import React from 'react';
import {
    Timeline,
    Public,
    TableChart,
    Flag,
    AssignmentTurnedIn,
    Receipt,
    Settings,
    AttachMoney
} from '@material-ui/icons';

const navBar = [
    {
        key: 'home',
        label: 'Inicio',
        icon: Timeline,
        url: '/'
    },
    {
        key: 'daily-report',
        label: 'Reporte global',
        icon: Public,
        url: '/reports/daily'
    },
    {
        key: 'branch-report',
        label: 'Report sucursal',
        icon: TableChart,
        url: '/reports/branch'
    },
    {
        key: 'goals',
        label: 'Metas',
        icon: Flag,
        url: '/goals'
    },
    {
        key: 'surveys',
        label: 'Encuestas',
        icon: AssignmentTurnedIn,
        url: '/surveys'
    },
    {
        key: 'transactions',
        label: 'Transacciones',
        icon: Receipt,
        url: '/transactions/list'
    },
    {
        key: 'settings',
        label: 'Ajustes',
        icon: Settings,
        url: '/settings'
    },

];

export default navBar;