import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Holder from '../../common/holder';
import Progress from '../../common/charts/progress';
import { prettyMoney } from '../../../utils/helpers';

const Branches = ({
    branches,
    getDetailBranch,
    startDate,
    endDate,
}) => {

    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getDetailBranch(startDate, endDate)
            .then(() => {
                setLoading(false);
            })
            .catch(err => console.error('err: ',err));
    }, [])
    return (
        <>
            <Holder>
                <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><p className="text-lg font-semibold">Sucursal</p></TableCell>
                                    <TableCell />
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {branches.map((row) => (
                                    <TableRow hover key={row.id}>
                                        <TableCell component="th" scope="row">
                                            {row.branch.name}
                                        </TableCell>
                                        <TableCell align="right">{prettyMoney(row.total)}</TableCell>
                                        <TableCell align="right">
                                            <Progress value={row.progress} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                
            </Holder>
        </>
        
    )
};

export default Branches;