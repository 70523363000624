import { reports as initialState } from '../config/initialState';
import Report from '../api/services/Report';
import { today, startOf, endOf, fullDate } from '../utils/dateFormatter';

const SET_REPORTS = '[REPORTS] SET_REPORTS';
const SET_DAILY = '[REPORTS] SET_DAILY';
const SET_BRANCH = '[REPORTS] SET_BRANCH';
const SET_SALES = '[REPORTS] SET_SALES';
const SET_GOALS_PEFORMANCE = '[REPORTS] SET_GOALS_PEFORMANCE';
const SET_SALES_COMPARISON = '[REPORTS] SET_SALES_COMPARISON';

export const setDaily = (stDt, endDt, { branches, amounts }) => ({ type: SET_DAILY, stDt, endDt, branches, amounts });
export const setBranch = (stDt, endDt, dates, branchId, branchName, amounts) => ({
    type: SET_BRANCH,
    stDt,
    endDt,
    dates,
    branchId,
    branchName,
    amounts
});
export const setSales = (series, amounts) => ({ type: SET_SALES, series, amounts });
export const setGoalsPerformance = ({ branches,
    overallCompletionPercentage,
    differenceIdeal,
    idealPercentage
}) => ({ type: SET_GOALS_PEFORMANCE, branches, overallCompletionPercentage, differenceIdeal, idealPercentage });
export const setSalesComparison = ({ salesComparison, salesAmts, goals }) => ({ type: SET_SALES_COMPARISON, salesComparison, salesAmts, goals });


export const getDaily = (stDt, endDt) => dispatch => (
    new Promise((resolve, reject) => {
        Report.getDaily(stDt, endDt)
            .then(({ data }) => {
                dispatch(
                    setDaily(
                        fullDate(startOf(stDt,'day')),
                        fullDate(endOf(endDt,'day')),
                        data
                    )
                );
                return resolve(data);
            }).catch(err => console.log('err getDaily: ', err));
    })
);

export const getBranch = (stDt, endDt, branchesId, branchName) => dispatch => (
    new Promise((resolve, reject) => {
        Report.getBranch(stDt, endDt, branchesId)
            .then(({ data }) => {
                dispatch(setBranch(stDt, endDt, data.branch, branchesId, branchName, data.amounts));
                return resolve(data);
            }).catch(err => console.log('err getbranch: ', err));
    })
);

export const getSales = (timeframe) => dispatch => (
    new Promise((resolve, reject) => {
        const startDate = fullDate(startOf(today(), 'month'));
        const endDate = fullDate(endOf(today(), 'month'));
        Report.getSales(startDate, endDate)
            .then(({ data }) => {
                dispatch(setSales(data.series, data.amounts));
            }).catch(err => console.log('err getting sales: ',err));
    })
);

export const getGoalsPerformance = (startDate, endDate) => dispatch => (
    new Promise((resolve, reject) => {
        Report.getGoalsPerformance(startDate, endDate)
            .then(({ data }) => {
                dispatch(setGoalsPerformance(data));
                return resolve(data);
            }).catch(err => console.log('err goals performance: ',err))
    })
)

export const getSalesComparison = (startDate = null, endDate = null, branchId = null) => dispatch => (
    new Promise((resolve, reject) => {
        Report.getSalesPeformanceComparison(startDate, endDate, branchId)
            .then(({ data }) => {
                dispatch(setSalesComparison(data));
                return resolve(data);
            }).catch(err => console.log('err sales performance: ',err))
    })
)

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_REPORTS:
            return {
                ...state,
            }
        case SET_DAILY:
            return {
                ...state,
                daily: {
                    stDt: action.stDt,
                    endDt: action.endDt,
                    branches: action.branches,
                    amounts: action.amounts
                },
            };
        case SET_BRANCH:
            return {
                ...state,
                branch: {
                    stDt: action.stDt,
                    endDt: action.endDt,
                    dates: action.dates,
                    branchId: action.branchId,
                    amounts: action.amounts
                },
            };
        case SET_SALES:
            return {
                ...state,
                sales: {
                    series: action.series,
                    amounts: action.amounts
                }
            }
        case SET_GOALS_PEFORMANCE:
            return {
                ...state,
                goalsPerformance: {
                    branches: action.branches,
                    idealPercentage: action.idealPercentage,
                    overallCompletionPercentage: action.overallCompletionPercentage,
                    differenceIdeal: action.differenceIdeal
                }
            };
        case SET_SALES_COMPARISON:
                return {
                    ...state,
                    salesComparison: action.salesComparison,
                    salesAmts: action.salesAmts
                }
        default: return state;
    }
}
