import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Holder from '../../common/holder';
import DatesPicker from '../../common/dates_picker';
import {
    fullDate, startOf, endOf, prettyDate, today
} from '../../../utils/dateFormatter';
import { prettyMoney } from '../../../utils/helpers';
import { getDetailDay, getPaymentDetails } from '../../../ducks/sales.ducks';
import { getBranches } from '../../../ducks/users.ducks';
import Pie from '../../common/charts/pie.charts';


const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const DayReport = ({
    dayDetail,
    getDayDetail,
    getDayBranches,
    branches,
    history,
    paymentsDetail,
    getDetailPayment,
    detailAmts,
    location,
}) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const params = new URLSearchParams(location.search);
    const stDtParam = params.get('startDate') ? startOf(params.get('startDate'), 'day') :  startOf(today(), 'month');
    const endDtParam = params.get('endDate') ? endOf(params.get('endDate'), 'day') : endOf(today(), 'month');
    const branchIdParam = params.get('branchId') ||  null;
    
    const [branchId, setBranchId] = useState(branchIdParam);
    const [startDate, setStartDate] = useState(stDtParam);
    const [endDate, setEndDate] = useState(endDtParam);

    const updateQueryStringParameter = (key, value)  => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set(key, value);
        location.search = searchParams.toString();
    }

    const handleStDt = date => {
        updateQueryStringParameter('startDate', date);
        setStartDate(date);
    }

    const handleEndDt = date => {
        updateQueryStringParameter('endDate', date);
        setEndDate(date);
    }

    const handleBranch = branchId => {
        updateQueryStringParameter('branchId', branchId);
        setBranchId(branchId);
    }

    useEffect(() => {
        getDayBranches();

        getDetailPayment(fullDate(startDate), fullDate(endDate), branchId)
            .then(() => {
                setLoading(false);
            })
            .catch(err => console.error('err: ',err));

        getDayDetail(fullDate(startDate), fullDate(endDate), branchId)
            .then(() => {
                setLoading(false);
            })
            .catch(err => console.error('err: ',err));
    }, [startDate, endDate, branchId]);
    return (
        <>
            <Holder>
                <DatesPicker
                    setStartDate={handleStDt}
                    setEndDate={handleEndDt}
                    startDate={fullDate(startDate)}
                    endDate={fullDate(endDate)}
                    branches={branches}
                    branchId={branchId}
                    setBranchId={handleBranch}
                />
            </Holder>
            <div className="flex flex-row">
                <div className="w-full mr-2">
                    <Holder>
                        <TableContainer>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><p className="text-md font-semibold">Fecha</p></TableCell>
                                        <TableCell><p className="text-md font-semibold">Sucursal</p></TableCell>
                                        <TableCell><p className="text-md font-semibold">Tax</p></TableCell>
                                        <TableCell><p className="text-md font-semibold">Total</p></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dayDetail.map((row) => (
                                        <TableRow hover key={row.id} onClick={() => history.push(`/sales/${row.id}`)}>
                                            <TableCell component="th" scope="row">
                                                {prettyDate(row.created_date)}
                                            </TableCell>
                                            <TableCell>{row.branch.name}</TableCell>
                                            <TableCell>{prettyMoney(row.tax)}</TableCell>
                                            <TableCell>{prettyMoney(row.total)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Holder>
                </div>
                <div className="w-5/12 ml-2">
                    <Holder>
                        <p className="font-semibold text-lg self-start">Métodos de pago</p>
                        <Pie
                            chartData={paymentsDetail}
                            name="Pagos"
                        />
                        {paymentsDetail.map(payment => {
                            return(
                                <div className="w-full flex flex-row justify-between">
                                    <p className="text-right text-base text-gray-500">{payment.name}</p>
                                    <p className="text-right text-base text-gray-500">{prettyMoney(payment.total)}</p>
                                </div>
                            )
                        })}
                        <div className="border border-opacity-90 border-black my-1 mb-2" />
                        <div className="w-full flex flex-row justify-between">
                            <p className="text-right text-base text-gray-500">Tax</p>
                            <p className="text-right text-base text-gray-500">{prettyMoney(detailAmts.tax)}</p>
                        </div>
                        <div className="w-full flex flex-row justify-between">
                            <p className="text-right text-base text-gray-500">Total</p>
                            <p className="text-right text-base text-gray-500">{prettyMoney(detailAmts.total)}</p>
                        </div>
                    </Holder>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = ({ users, sales }) => ({
    dayDetail: sales.detail.day,
    branches: users.branches,
    paymentsDetail: sales.detail.payments,
    detailAmts: sales.detail.amounts
});

const mapDispatchToProps = dispatch => ({
    getDayDetail: (startDate, endDate, branchId) => dispatch(getDetailDay(startDate, endDate, branchId)),
    getDayBranches: () => dispatch(getBranches()),
    getDetailPayment: (startDate, endDate, branchId) => dispatch(getPaymentDetails(startDate, endDate, branchId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DayReport);