import React from 'react';
import './index.css';

// REDUX
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { Provider } from 'react-redux';

// ROUTER
import { Router, Redirect, Switch } from 'react-router-dom';
import PublicRoute from './utils/PublicRoute';
import PrivateRoute from './utils/PrivateRoute';
import history from './utils/history';
// import PrivateRoute from './utils/PrivateRoute';

// COMPONENTS
import Template from './components/common/template/index.template';
import Home from './components/home/index.home';
import Login from './components/login/index.login';

// REPORTS
import Daily from './components/reports/reports.daily';
import Branch from './components/reports/reports.branch';
import DayReport from './components/sales/reports/day.report';
import SaleDetail from './components/sales/reports/detail.report';
import SaleModule from './components/home/sales.home';

// GOALS
import Goals from './components/goals/goals.index';
import CreateGoal from './components/goals/goals.create';

// SURVEYS
import SurveyClient from './components/surveys/client/index.client';
import SurveyThanks from './components/surveys/client/thanks.client';
import SurveysAdmin from './components/surveys/admin/index.admin';
import AnsweredSurveyDetail from './components/surveys/admin/detail.admin';

// TRANSACTIONS
import CreateTransaction from './components/transactions/transactions.create';
import Txns from './components/transactions/transactions.list';

// SETTINGS
import Settings from './components/settings/index.settings';
import rootReducer from './ducks';

import 'bootstrap/dist/css/bootstrap.min.css';

const store = createStore(
    rootReducer,
    compose(
        applyMiddleware(thunk, logger),
    )
);

function App() {
    return (
        <div className="App">
            <Provider store={store}>
                <Router history={history}>
                    <Switch>
                        <PublicRoute path='/login' component={Login} />
                        <PublicRoute exact path='/survey/:surveyId/:branchId' component={SurveyClient} />
                        <PublicRoute exact path='/survey/thanks' component={SurveyThanks} />
                        <Template>
                            <PrivateRoute exact path='/' component={SaleModule} />
                            <PrivateRoute exact path='/reports/daily' component={Daily} />
                            <PrivateRoute exact path='/reports/branch' component={Branch} />

                            <PrivateRoute path='/reports/day' component={DayReport} />
                            <PrivateRoute exact path='/sales/:saleId' component={SaleDetail} />
                            {/* <PrivateRoute exact path='/sales' component={SaleModule} /> */}

                            <PrivateRoute exact path='/transactions/create' component={CreateTransaction} />
                            <PrivateRoute exact path='/transactions/list' component={Txns} />
                            <PrivateRoute exact path='/goals' component={Goals} />
                            <PrivateRoute exact path='/goals/create' component={CreateGoal} />
                            <PrivateRoute exact path='/surveys' component={SurveysAdmin} />
                            <PrivateRoute exact path='/surveys/:answeredSurveyId' component={AnsweredSurveyDetail} />

                            <PrivateRoute exact path='/settings' component={Settings} />
                        </Template>
                    </Switch>
                </Router>
            </Provider>
        </div>
    );
}

export default App;
