import React, { useEffect, useState } from 'react';
import Progress from '../common/charts/progress';
import DummyLine from '../common/charts/dummy_line';

const CardElements = ({
    elements,
}) => {
    const Card = ({ title, content, subcontent,  }) => {
        return (
            <div className="md:flex-col">
                <p className="mb-3 text-xl font-sm">{title}</p>
                <p className="mb-1 text-3xl font-black">{content}</p>
                <p className="text-lg text-gray-500 font-medium">{subcontent}</p>
            </div>
        )
    }
    return (
        <div
            className="
                p-3 px-4 bg-white rounded-lg shadow-sm mb-3 justify-center flex flex-col items-center
                md:flex md:flex-row md:w-full md:place-content-around
            "
        >
            {elements.map((element, i) => (
                <div
                    className="w-full flex flex-row items-center place-content-around"
                >
                    <Card
                        key={i}
                        title={element.title}
                        content={element.content}
                        subcontent={element.subcontent}
                    />
                    <div className="ml-3">
                        
                        {element.type === 'progress' ?
                            <Progress value={element.progress} />
                            :
                            <DummyLine tendency={element.tendency} />
                        }
                        
                    </div>
                </div>
            ))}
        </div>
    )
};

export default CardElements;