import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Input,
    Chip
} from '@material-ui/core';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
    style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
    },
    },
};

const useStyles = makeStyles((theme) => ({
    formControl: {
    margin: theme.spacing(1),
    minWidth: 420,
    maxWidth: 420,
    },
    chips: {
    display: 'flex',
    flexWrap: 'wrap',
    },
    chip: {
    margin: 2,
    },
    noLabel: {
    marginTop: theme.spacing(3),
    },
}));


const SelectMultiple = ({ options, value, id, handleChange, label  }) => {
    const classes = useStyles();

    const onChange = (event) => {
        handleChange(event.target.value);
    };
    return (
        <FormControl className={classes.formControl}>
        <InputLabel id={`${id}-label`}>{label}</InputLabel>
            <Select
                labelId={`${id}-label`}
                id="demo-mutiple-chip"
                multiple
                value={value}
                onChange={onChange}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                    <div className={classes.chips}>
                        {selected.map((value) => {
                            const current = options.find(option => option.id === value);
                            return <Chip key={value} label={current.name} className={classes.chip} />

                        })}
                    </div>
                )}
                MenuProps={MenuProps}
            >
                {options.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
};

export default SelectMultiple;