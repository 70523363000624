import { sales as initialState } from '../config/initialState';
import Sales from '../api/services/Sales';
import { prettyMoney } from '../utils/helpers';

const  SET_DETAIL_DAY = '[SALES] SET_DETAIL_DAY';
const SET_SALE_DETAIL = '[SALES] SET_SALE_DETAIL';
const SET_DETAIL_BRANCH = '[SALES] SET_DETAIL_BRANCH';
const SET_DETAIL_PAYMENT = '[SALES] SET_DETAIL_PAYMENT';

export const setDetailDay = ({ dates, amounts }) => ({ type: SET_DETAIL_DAY, dates, amounts });
export const setSaleDetail = ({ expenses, payments, sale, branch }) => ({ type: SET_SALE_DETAIL, expenses, payments, sale, branch });
export const setDetailBranch = ({ sales }) => ({ type: SET_DETAIL_BRANCH, branches: sales });
export const setDetailPayment =     ({ payments }) => ({ type: SET_DETAIL_PAYMENT, payments });

export const getDetailDay = (startDate, endDate, branchId) => dispatch =>(
    new Promise((resolve, reject) => {
        // startDate = '2020-10-01';
        // endDate = '2020-10-30';
        Sales.getDetailDay(startDate, endDate, branchId)
            .then(({ data }) => {

                dispatch(setDetailDay(data));
                return resolve(data);
            })
            .catch(err => {
                console.error('err: ',err);
                return err;
            });
    })
);

export const getSaleById = (saleId) => dispatch => (
    new Promise((resolve, reject) => {
        Sales.getSaleById(saleId)
            .then(({ data }) => {
                console.log('daata: ',data);
                const paymentsFixed = data.payments.map(payment => ({
                    field: payment.paymentMethod.name,
                    value: prettyMoney(payment.total),
                }));
                const expensesFixed = data.expenses.map(expense => ({
                    field: expense.description,
                    value: prettyMoney(expense.total)
                }))
                dispatch(setSaleDetail({
                    expenses: expensesFixed,
                    payments: paymentsFixed,
                    sale: data.sale
                }));
                return resolve(data);
            })
            .catch(err => {
                console.error('err saleId: ',err);
                return err;
            });
    })
);


export const getDetailBranch = (startDate, endDate, branchId) => dispatch => (
    new Promise((resolve, reject) => {
        Sales.getDetailBranch(startDate, endDate, branchId)
            .then(({ data }) => {
                console.log('daata: ',data);

                dispatch(setDetailBranch(data));
                return resolve(data);
            })
            .catch(err => {
                console.error('err saleId: ',err);
                return reject(err);
            });
    })
);

export const getPaymentDetails = (startDate, endDate, branchId) => dispatch => (
    new Promise((resolve, reject) => {
        Sales.getPaymentsDetail(startDate, endDate, branchId) 
            .then(({ data }) => {
                console.log('dattA:---', data);
                dispatch(setDetailPayment(data));
                return resolve(data);
            })
            .catch(err => console.error('err: ',err));
    })
)

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DETAIL_DAY:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    day: action.dates,
                    amounts: action.amounts.pop()
                } 
            };
        case SET_SALE_DETAIL:
                return {
                    ...state,
                    saleDetail: {
                        payments: action.payments,
                        expenses: action.expenses,
                        branch: action.branch,
                        sale: action.sale
                    }
                }
        case SET_DETAIL_BRANCH:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    branches: action.branches
                }
            }
        case SET_DETAIL_PAYMENT:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    payments: action.payments
                }
            }
        default: return state;
    }
};


