import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getSurveysPerformance, getAnsweredSurveys } from '../../../ducks/surveys.ducks';
import { Typography } from '@material-ui/core';
import Header from '../../common/header/index.header';
import BranchesPerformance from './branches.admin';
import QuestionsPerformance from './questions.admin';
import AnsweredSurveysList from './list.admin';
import Loading from '../../common/loading';

const SurveysAdmin = ({
    getSurveysPerformanceAdmin,
    branches,
    questionsPerformance,
    getAnsweredSurveysList,
    answeredSurveys,
    history,
}) => {
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getSurveysPerformanceAdmin()
            .then(data => {
                setLoading(false);
            })
            .catch(err => console.log('errr: ',err));
    }, []);
    if (loading) return <Loading />
    return (
        <div>
            <Header
                title="Encuestas"
                subtitle="Monitorea el rendimiento de las encuestas"
            />
            <div style={{ marginBottom: '2em' }}>
                <Typography variant="subtitle1">Encuestas por sucursal</Typography>
                <BranchesPerformance branches={branches} />
            </div>
            <div style={{ marginBottom: '2em' }}>
                <Typography variant="subtitle1">Rendimiento de preguntas</Typography>
                <QuestionsPerformance questionsPerformance={questionsPerformance} />
            </div>
            <div>
                <Typography variant="subtitle1">Últimas respuestas</Typography>
                <AnsweredSurveysList
                    getAnsweredSurveysList={getAnsweredSurveysList}
                    answeredSurveys={answeredSurveys}
                    history={history}
                />
            </div>
        </div>
    )
};

const mapStateToProps = ({ surveys }) => ({
    branches: surveys.branches,
    questionsPerformance: surveys.questionsPerformance,
    answeredSurveys: surveys.answeredSurveys,
});

const mapDispatchToProps = dispatch => ({
    getSurveysPerformanceAdmin: () => dispatch(getSurveysPerformance()),
    getAnsweredSurveysList: () => dispatch(getAnsweredSurveys())
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveysAdmin);