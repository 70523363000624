import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Fab from '../common/fab';    
import SimpleTable from './list.table';
import { getTxns } from '../../ducks/transactions.ducks';
import Loading from '../common/loading';
import Header from '../common/header/index.header';
import { getBankAccounts, getBusinessEntities } from '../../ducks/users.ducks';


const Txns = ({
    txns,
    getTxnsTransactions,
    getTxnsBankAccounts,
    getTxnsBusinessEntities,
    history,
    admin,
    amounts,
}) => {
    const [loading, setLoading] = useState(true);

    const [businessEntities, setBusinessEntities] = useState(admin.entitiesIds);
    const [bankAccounts, setBankAccounts] = useState(admin.accountsIds);
    const [type, setType] = useState(0);

    useEffect(() => {
        getTxnsBankAccounts();
        getTxnsBusinessEntities();
    }, []);

    useEffect(() => {
            setBankAccounts(admin.accountsIds);
            setBusinessEntities(admin.entitiesIds);
    }, [admin.entitiesIds, admin.accountsIds]);

    useEffect(() => {
        getTxnsTransactions(businessEntities, bankAccounts, type)
            .then((res) => {
                setLoading(false)
            });
    }, [businessEntities, bankAccounts, type]);

    return(
        <div>
            <Header
                title="Transacciones"
                subtitle="Maneja los ingresos y egresos"
            />
            {loading ?
                <Loading /> :
                <SimpleTable
                    admin={admin}
                    data={txns}
                    isEmpty={txns.length <= 0}
                    amounts={amounts}
                    businessEntities={{ current: businessEntities, setBusinessEntities }}
                    bankAccounts={{ current: bankAccounts, setBankAccounts }}
                    type={{ current: type, setType }}
                />
            }
            <Fab
                onClick={() => history.push('/transactions/create')}
            />
        </div>
    )
};

const mapStateToProps = ({ users, transactions: { txns, amounts }}) => {
    return {
        txns,
        admin: users,
        amounts
    }
};

const mapDispatchToProps = dispatch => ({
    getTxnsTransactions: (businessEntities, bankAccounts, type) =>
        dispatch(getTxns(businessEntities, bankAccounts, type)),
    getTxnsBankAccounts: () => dispatch(getBankAccounts()),
    getTxnsBusinessEntities: () => dispatch(getBusinessEntities()),
});

Txns.propTypes = {
    txns: PropTypes.arrayOf(Object),
    admin: PropTypes.shape({}),
    getTxnsTransactions: PropTypes.func,
    getTxnsBusinessEntities: PropTypes.func,
    getTxnsBankAccounts: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Txns);
