import React from 'react';
import {
    FormControl,
    InputAdornment,
    InputLabel,
    OutlinedInput
} from '@material-ui/core';

const NumberInput = ({ id, value, label, handleChange, placeholder, style, disabled }) => {
    const onChange = (e) => {
        handleChange(e.target.value);
    }
    return (
        <FormControl variant="outlined" style={{ marginBottom: '2em', ...style }}>
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <OutlinedInput
                id={id}
                type="number"
                placeholder={placeholder ? placeholder : "0.00"}
                value={value}
                onChange={onChange}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                labelWidth={60}
                disabled={disabled}
            />
        </FormControl>
    )
};

export default NumberInput;