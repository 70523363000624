import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper
} from '@material-ui/core';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

const BranchesPerformance = ({
    branches,
    complete,
    activeMonth,
}) => {
    const classes = useStyles();
    
    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell><strong>Sucursal</strong></TableCell>
                        <TableCell><strong>Respuestas</strong></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {branches.map((branch) => (
                    <TableRow key={branch.branchId}>
                        <TableCell component="th" scope="row">
                            {branch.branch.name}
                        </TableCell>
                        <TableCell>{branch.answersCnt}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
};

export default BranchesPerformance;