import { goals as initialState } from '../config/initialState';
import Goals from '../api/services/Goals';

const SET_CURRENT_MONTH_CHECK = '[GOALS] SET_CURRENT_MONTH_CHECK';
const SET_ACTIVE_BRANCH = '[GOALS] SET_ACTIVE_BRANCH';

export const setMonthCheck = ({ missingBranches, branches, activeMonth, complete }) =>
    ({ type: SET_CURRENT_MONTH_CHECK, complete, branches, missingBranches, activeMonth });

export const setActiveBranch = (branchId, branchName, targetDate) => ({
    type: SET_ACTIVE_BRANCH,
    branchId,
    branchName,
    targetDate
});

export const checkMonth = (month = new Date()) => dispatch => (
    new Promise((resolve, reject) => {
        Goals.checkCurrentMonth(month)
            .then(({ data }) => {
                console.log('data: ',data);
                dispatch(setMonthCheck(data));
                return resolve(data);
            }).catch(err => console.log('err checking goals month: ',err))
    })
);

export const createGoal = (amount) => (dispatch, getState) => (
    new Promise((resolve, reject) => {
        const { goals: { create } } = getState();
        Goals.create(create.branchId, create.targetDate, amount)
            .then(res => {
                console.log('res: ', res);
                return resolve(res);
            }).catch(err => console.log('err creating goal: '.err));
    })
);

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_MONTH_CHECK:
            return {
                ...state,
                activeMonth: action.activeMonth,
                branches: action.branches,
                missingBranches: action.missingBranches,
                complete: action.complete
            };
        case SET_ACTIVE_BRANCH:
            return {
                ...state,
                create: {
                    branchId: action.branchId,
                    branchName: action.branchName,
                    targetDate: action.targetDate
                }
            }
        default: return state;
    }
};
