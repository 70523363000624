import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { NavigateBefore } from '@material-ui/icons';


const Header = ({ title, subtitle, action, enableBack, url, history }) => {
    const goBack = () => {
        history.goBack();
    }
    return (
        <div style={{ marginBottom: '1.5em', marginTop: '1em' }}>
            {enableBack && (
                <Button style={{ marginBottom: '.5em'}} onClick={() => goBack()}>
                    <NavigateBefore />
                    <Typography variant="subtitle2">Regresar</Typography>
                </Button>
            )}
            <Typography variant="h5" component="h6">{title}</Typography>
            <Typography variant="subtitle1" color="textSecondary">{subtitle}</Typography>
        </div>
    )
}

export default Header;