import React from 'react';
import { Button } from '@material-ui/core';

const SurveyThanks = ({ history }) => {
    return (
        <div
            style={{
                display: 'flex',
                width: '100vw',
                height: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid red'
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '90%', margin: 'auto', textAlign: 'center' }}>
                <h1 style={{ marginBottom: '1em' }}>¡Muchas gracias por tu tiempo! 🙌</h1>
                <p style={{ color: 'gray', fontSize: '1.1em', marginBottom: '2em' }}>Tu retroalimentación significa todo para nosotros</p>
                <p style={{ color: 'gray', fontSize: '1.1em', marginBottom: '2em' }}>Utiliza el siguiente cupón para obtener un 10% en tu siguiente comprar en nuestra página en linea.</p>
                <Button
                    style={{ width: '100%' }}   
                    variant="contained"
                    color="primary"
                    onClick={() => window.location = 'https://grupomara.mx' }
                >
                        CANJEAR
                </Button>
            </div>
        </div>
    )
}

export default SurveyThanks;