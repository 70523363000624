import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import {
    Chart,
    BarSeries,
    Title,
    ArgumentAxis,
    ValueAxis,
  } from '@devexpress/dx-react-chart-material-ui';
  
import { Animation } from '@devexpress/dx-react-chart';

const SalesChart = ({
    series
}) => {
    const [loading, setLoading] = useState(false);
    const [timeframe, setTimeframe] = useState('');
    
    return (
        <Paper style={{ padding: '1em' }}>
            <Chart
                data={series}
            >
                <ArgumentAxis />
                <ValueAxis max={7} />

                <BarSeries
                    valueField="amount"
                    argumentField="date"
                />
                
                <Title text="Venta" />
                <Animation />
            </Chart>
        </Paper>

    )
};

export default SalesChart;