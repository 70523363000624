import React from 'react';
import Holder from '../common/holder';

const DetailRenderer = ({ details, header}) => {
    return(
        <div className="flex flex-col w-full mb-3">
            <p className="font-semibold text-lg">{header}</p>
            <div className="border border-opacity-90 border-black my-1 mb-2" />
            {details.map(detail => {
                return (
                    <div className="flex flex-row justify-between items-center">
                        <p className="text-base mb-1 text-gray-500">{detail.field}</p>
                        <p className="text-base text-gray-500">{detail.value}</p>
                    </div>
                )
            })}
        </div>
    )
};

export default DetailRenderer;