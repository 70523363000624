import React from 'react';
import { Typography } from '@material-ui/core';
import Text from '../common/text';

const Account = ({ me }) => {
    const items = [
        { id: 1,title: 'Nombre', subtitle: me.name },
        { id: 2,title: 'Correo', subtitle: me.email },
        { id: 3,title: 'Teléfono', subtitle: me.phone },
        { id: 4,title: 'Alias', subtitle: me.alias },
    ]
    return (
        <>
            {items.map(item => <Text key={item.id} title={item.title} subtitle={item.subtitle} />)}
        </>
    )
};

export default Account;