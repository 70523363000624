import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Paper, Typography } from '@material-ui/core';
import { getSales, getGoalsPerformance, getSalesComparison } from '../../ducks/reports';
import { getBranches, getMe } from '../../ducks/users.ducks';
import Header from '../common/header/index.header';
import Loading from '../common/loading';
import CardElements from '../common/cards';
import Day from './charts/day.sales';
import Days from './charts/days.home';
import Branches from './charts/branches.home';
import { prettyMoney, prettyNumber, prettyPercentage } from '../../utils/helpers';
import { getDetailBranch } from '../../ducks/sales.ducks';
import { startOf, endOf, fullDate, today } from '../../utils/dateFormatter';

const Home = ({
    getSalesHome,
    me,
    getHomeMe,
    branches,
    salesComparison,
    getHomeSalesComparison,
    salesAmts,
    sales,
    getBranchDetail,
    history
}) => {
    const [loading, setLoading] = useState(true);
    const [timeframe, setTimeframe] = useState('');
    
    const [startDate, setStartDate] = useState(fullDate(startOf(today(), 'month')));
    const [endDate, setEndDate] = useState(fullDate(endOf(today(), 'month')));

    useEffect(() => {
        setLoading(true);
        getHomeMe()
            .then(() => setLoading(false));
    }, []);

    
    useEffect(() => {
        setLoading(true);

        getSalesHome(timeframe)
            .then(() => setLoading(false))
            .catch(err => console.log('err comp chart: ',err));
        
        getHomeSalesComparison(startDate, endDate)
            .then((data) => {
                console.log('data: ',data);
                setLoading(false);
            })

        // performanceGetGoalsPerformance()
        //     .then(() => setLoadingPerformance(false))
        //     .catch(err => console.log('err peformance: ',err));

    }, [timeframe]);
 
    if(loading) return <Loading />
    return (
        <div>
            <Header title={`¡Hola, ${me.name}!`} subtitle="Los montos son del mes actual" />
            <CardElements
                elements={[
                    {
                        title: 'Ingresos',
                        content: prettyMoney(salesAmts.ctAmt),
                        subcontent: prettyMoney(salesAmts.ltAmt),
                        tendency: salesAmts.tendency,
                        type: 'linear'
                    },
                    {
                        title: 'Meta',
                        content: prettyMoney(salesAmts.goalTotal),
                        subcontent: Number(salesAmts.goalXIdeal).toFixed(2),
                        tendency: false,
                        progress: salesAmts.goalDifference,
                        type: 'progress'
                    },
                ]}
                style={{ marginBottom: '1em' }}
            />
            <div className="">
                <Day
                    data={salesComparison}
                />
            </div>

            <div className="md:flex md:flex-row justify-between w-full">
                <div className="w-full md:w-10/12">
                    <Days
                        dates={salesComparison}
                        history={history}
                    />
                </div>
                <div className="w-full md:w-10/12">
                    <Branches
                        branches={sales.detail.branches}
                        getDetailBranch={getBranchDetail}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ sales, users: { me }, reports: { goalsPerformance, salesComparison, salesAmts } }) => ({
    me,
    differenceIdeal: goalsPerformance.differenceIdeal,
    branches: goalsPerformance.branches,
    overallCompletionPercentage: goalsPerformance.overallCompletionPercentage,
    salesComparison,
    salesAmts,
    sales,
});

const mapDispatchToProps = dispatch => ({
    getSalesHome: (timeframe) => dispatch(getSales(timeframe)),
    getHomeMe: () => dispatch(getMe()),
    performanceGetGoalsPerformance: (startDate, endDate) => dispatch(getGoalsPerformance(startDate, endDate)),
    getHomeSalesComparison: (startDate, endDate) => dispatch(getSalesComparison(startDate, endDate)),
    getBranchDetail: (startDate, endDate, branchId) => dispatch(getDetailBranch(startDate, endDate, branchId))
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
