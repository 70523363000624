import api from '../index';

class Goals {
    static getDetailDay(startDate, endDate, branchId) {
        const branch = branchId ? `&branchId=${branchId}` : '';
        return new Promise((resolve, reject) => (
            api.get(`/sales/detail/day?startDate=${startDate}&endDate=${endDate}${branch}`)
                .then(response => resolve(response.data))
                .catch(err => reject(err))
        ))
    }

    static getDetailBranch(startDate, endDate) {
        return new Promise((resolve, reject) => (
            api.get(`/sales/performance/branch?startDate=${startDate}&endDate=${endDate}`)
                .then(response => resolve(response.data))
                .catch(err => reject(err))
        ))
    }

    static getSaleById(saleId) {
        return new Promise((resolve, reject) => {
            api.get(`/sales/${saleId}`)
                .then(response => resolve(response.data))
                .catch(err => reject(err))
        });
    }

    static getPaymentsDetail(startDate, endDate, branchId, saleId) {
        const branch = branchId ? `&branchId=${branchId}` : '';
        return new Promise((resolve, reject) => (
            api.get(`/sales/payments?startDate=${startDate}&endDate=${endDate}${branch}`)
                .then(response => resolve(response.data))
                .catch(err => reject(err))
        ));
    }

}

export default Goals;