import React, { useEffect, usetate } from 'react';
import { connect } from 'react-redux';
import { getSaleById } from '../../../ducks/sales.ducks';
import { prettyDate } from '../../../utils/dateFormatter';
import DetailRenderer from '../../common/detail_render';
import Holder from '../../common/holder';
import Header from '../../common/header/index.header';

const DetailReport = ({
    detail,
    getDetailSale,
    match,
    history
}) => {
    const { saleId } = match.params;
    useEffect(() => {
        getDetailSale(saleId)
            .then(() => {
                setLoading(false);
            })
            .catch(err => console.error(err))
    }, [saleId]);

    return (
        <>
        <div className="p-3">
            <Header
                title="Detalle de venta"
                subtitle="Revisa el detalle"
                enableBack
                history={history}
            />
        </div>
            <Holder>
                <DetailRenderer
                    header="Detalles"
                    details={[
                        { field: 'Fecha', value: prettyDate(detail.sale.createdDate) },
                        { field: 'Ticket inicio', value: detail.sale.startId },
                        { field: 'Ticket fin', value: detail.sale.endId },
                        { field: 'Comentarios', value: detail.sale.comments  },
                        
                    ]}
                />
                <DetailRenderer
                    header="Métodos de pago"
                    details={detail.payments}
                />
                <DetailRenderer
                    header="Gastos"
                    details={detail.expenses}
                />
            </Holder>
        </>
    )
};


const mapStateToProps = ({ sales }) => ({
    detail: sales.saleDetail
});

const mapDispatchToProps = dispatch => ({
    getDetailSale: (saleId) => dispatch(getSaleById(saleId)),
});

export default connect(mapStateToProps, mapDispatchToProps) (DetailReport);