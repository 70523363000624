import React, { useState, useEffect }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Form } from 'react-bootstrap';
// date range picker
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import EmptyState from '../common/empty';
import Loading from '../common/loading';
import Header from '../common/header/index.header';
import { getDaily } from '../../ducks/reports';
import { today, prettyDate, fullDate, startOf, endOf } from '../../utils/dateFormatter';
import { prettyMoney } from '../../utils/helpers';

const Daily = ({
    branches,
    getReportsDaily,
    amounts
}) => {
    const [loading, setLoading] = useState(false);

    const [startDate, setStartDate] = useState(fullDate(startOf(today(), 'month')));
    const [endDate, setEndDate] = useState(fullDate(endOf(today(), 'month')));
    const handleDateChange = (date, type) => {
        let setDate = type === 'start' ? setStartDate : setEndDate;
        setDate(date);
    };

    useEffect(() => {
        setLoading(true);
        if (startDate !== '' && endDate !== '') {
            getReportsDaily(startDate, endDate)
            .then((res) => {
                setLoading(false);
            }).catch(err => err);
        }
    }, [startDate, endDate]);

    const DataTable = () => (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th rowSpan={2}>Sucursal</th>
                    <th colSpan={3}>Tarjetas</th>
                    <th colSpan={4}>Totales</th>
                </tr>
                <tr>
                    <th>Santander</th>
                    <th>BanBajio</th>
                    <th>Amex</th>
                    <th>Coppel</th>
                    <th>Dolares</th>
                    <th>Efectivo</th>
                    <th>Tarjetas</th>
                    <th>Corte</th>
                    <th>Venta día</th>
                </tr>
            </thead>
            <tbody>
                {branches.map(branch => (
                    <tr key={branch.id_suc}>
                        <td>{ branch.branch }</td>
                        <td>{prettyMoney(branch.santander)}</td>
                        <td>{prettyMoney(branch.bbjio)}</td>
                        <td>{prettyMoney(branch.amex)}</td>
                        <td>{prettyMoney(branch.coppel)}</td>
                        <td>{prettyMoney(branch.dolares)}</td>
                        <td>{prettyMoney(branch.efectivo)}</td>
                        <td>{prettyMoney(branch.tarjeta)}</td>
                        <td>{prettyMoney(branch.corte)}</td>
                        <td>{prettyMoney(branch.tot_dia)}</td>
                    </tr>
                ))}
                <tr key="amounts">
                        <td><strong>Totales</strong></td>
                        <td>{prettyMoney(amounts.santander)}</td>
                        <td>{prettyMoney(amounts.bbjio)}</td>
                        <td>{prettyMoney(amounts.amex)}</td>
                        <td>{prettyMoney(amounts.coppel)}</td>
                        <td>{prettyMoney(amounts.dolares)}</td>
                        <td>{prettyMoney(amounts.efectivo)}</td>
                        <td>{prettyMoney(amounts.tarjeta)}</td>
                        <td>{prettyMoney(amounts.corte)}</td>
                        <td>{prettyMoney(amounts.tot_dia)}</td>
                    </tr>
            </tbody>
        </Table>
    );

    return (
        <div>
            <Header
                title="Reporte global"
                subtitle="Revisa las ventas de tus sucursales"
            />
                <div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd MMMM yyyy"
                            margin="normal"
                            id="date-picker-inline-start"
                            label="Fecha de inicio"
                            value={startDate}
                            onChange={(value) => handleDateChange(value, 'start')}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd MMMM yyyy"
                            margin="normal"
                            id="date-picker-inline-end"
                            label="Fecha de cierre"
                            value={endDate}
                            onChange={(value) => handleDateChange(value, 'end')}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                </MuiPickersUtilsProvider>
            </div>
            <div>
                {loading ? 
                    <Loading />
                    : branches.length > 0 ? (
                        <DataTable />
                ): (
                    <EmptyState />
                )}
            </div>
        </div>
    );
};

const mapStateToProps = ({ reports: { daily } }) => ({
    branches: daily.branches,
    amounts: daily.amounts
});

const mapDispatchToProps = dispatch => ({
    getReportsDaily: (stDt, endDt) => dispatch(getDaily(stDt, endDt)),
});

Daily.propTypes = {
    getReportsDaily: PropTypes.func,
    branches: PropTypes.arrayOf(Object),
    amounts: PropTypes.shape({})
}

export default connect(mapStateToProps, mapDispatchToProps)(Daily);
