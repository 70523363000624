import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Chip,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';
import SelectMultiple from '../common/inputs/selectMultiple.input';
import Switch from '../common/inputs/switch.input';
import Empty from '../common/empty';
import { prettyDate } from '../../utils/dateFormatter';
import { prettyMoney } from '../../utils/helpers';
import { CHANNELS_CATALOG } from '../../ducks/transactions.ducks';
import {
    Money,
    CreditCard,
    SyncAlt,
    Help
} from '@material-ui/icons';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});


export default function SimpleTable({ admin, data, businessEntities, bankAccounts, type, amounts, isEmpty }) {
    const classes = useStyles();

    const iconMap = (channel) => {
        const {
            TRANSFER,
            CASH,
            REVOLVING_CREDIT,
            DIRECT_CREDIT,
            CHECK,
            INSIDE_TRANSFER
        } = CHANNELS_CATALOG;
        switch(channel) {
            case INSIDE_TRANSFER:
            case TRANSFER: return <SyncAlt />;

            case CASH:  return <Money />;

            case REVOLVING_CREDIT:
            case DIRECT_CREDIT: return <CreditCard />;
            default:  return <Help />;
        }
    }

    const TableTxns = () => (
        <>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ fontWeight: '600' }} align="left">Subtotal</TableCell>
                        <TableCell style={{ fontWeight: '600' }} align="left">Tax</TableCell>
                        <TableCell style={{ fontWeight: '600' }} align="left">Total</TableCell>

                        <TableCell style={{ fontWeight: '600' }} align="left">Ingresos</TableCell>
                        <TableCell style={{ fontWeight: '600' }} align="left">Egresos</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                        <TableRow>
                            <TableCell align="left">{ prettyMoney(amounts.subtotal) }</TableCell>
                            <TableCell align="left">{ prettyMoney(amounts.tax) }</TableCell>
                            <TableCell align="left">{ prettyMoney(amounts.total) }</TableCell>
                            <TableCell align="left">{ prettyMoney(amounts.ingress) }</TableCell>
                            <TableCell align="left">{ prettyMoney(amounts.egress) }</TableCell>
                        </TableRow>
                </TableBody>
            </Table>


            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left" />
                        <TableCell align="left" />
                        <TableCell style={{ fontWeight: '600' }} align="left">Fecha</TableCell>
                        <TableCell style={{ fontWeight: '600' }} align="left">Concepto</TableCell>
                        {/* <TableCell style={{ fontWeight: '600' }} align="left">Cuenta</TableCell> */}
                        {/* <TableCell style={{ fontWeight: '600' }} align="left">Razon</TableCell> */}
                        {/* <TableCell style={{ fontWeight: '600' }} align="left">Categoria</TableCell> */}
                        {/* <TableCell style={{ fontWeight: '600' }} align="left">Subtotal</TableCell> */}
                        <TableCell style={{ fontWeight: '600' }} align="left">IVA</TableCell>
                        <TableCell style={{ fontWeight: '600' }} align="left">Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>
                            <Chip
                                label={row.type === 1 ? 'ING' : 'EGR' }
                                color={row.type === 1 ? 'primary' : 'secondary'}
                            />
                            </TableCell>
                            <TableCell>
                                {iconMap(row.luTransactionChannel.id)}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {prettyDate(row.issuedDate)}
                            </TableCell>
                            <TableCell align="left">{ row.concept }</TableCell>
                            {/* <TableCell align="left">{ row.account_name }</TableCell> */}
                            {/* <TableCell align="left">{ row.entity }</TableCell> */}
                            { /* <TableCell align="left">{ row.category }</TableCell> */ }
                            { /* <TableCell align="left">{ prettyMoney(row.subtotal) }</TableCell> */ }
                            <TableCell align="left">{ prettyMoney(row.tax) }</TableCell>
                            <TableCell align="left">{ prettyMoney(row.total) }</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    )

    return (
        <TableContainer component={Paper}>
            <SelectMultiple
                label="Razón social"
                options={admin.businessEntities}
                value={businessEntities.current}
                handleChange={(value) => businessEntities.setBusinessEntities(value)}
            />
            <SelectMultiple
                label="Cuenta"
                options={admin.bankAccounts}
                value={bankAccounts.current}
                handleChange={(value) => bankAccounts.setBankAccounts(value)}
            /> 
            {!isEmpty ? <TableTxns /> : <Empty copy="Aún no cuentas con movimientos" />}
            
        </TableContainer>
    );
}
