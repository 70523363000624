import { combineReducers } from 'redux';
import { reducer as reports } from './reports';
import { reducer as users } from './users.ducks';
import { reducer as transactions } from './transactions.ducks';
import { reducer as goals } from './goals.ducks';
import { reducer as surveys } from './surveys.ducks';
import { reducer as sales } from './sales.ducks';

const mainReducer = combineReducers({
    reports,
    users,
    transactions,
    goals,
    surveys,
    sales
});

export default mainReducer;
