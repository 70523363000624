import React, { useState } from 'react';
import {
    List,
    ListItem,
    ListItemText,
    Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';  
import Text from '../common/text';
import InputText from '../common/inputs/text.input';
import SelectInput from '../common/inputs/select.input';
import Modal from '../common/modal';



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

const ListGeneric = ({ items, panel, handleSubmit }) => {
    const classes = useStyles();
    const [activeItem, setActiveItem] = useState(null);
    const [formValues, setFormValues] = useState();
    const [modalOpen, setModal] = useState(false);

    const handleToggle = value => {
        setActiveItem(value);
    }

    const getStyles = (id) => {
        if (activeItem && id === activeItem.id) {
            return {
                backgroundColor: '#2185d0',
                color: 'white',
                borderRadius: 5
            }
        }
        return {};
    }

    const handleChange = (value, field) => {
        setFormValues({
            ...formValues,
            [field]: value
        });
    }
    return (
        <>
            <Modal
                open={modalOpen}
                handleClose={() => setModal(false)}
            >
                <div>
                    {panel.map(row => {
                        switch(row.type) {
                            case 'select':
                                const field = row.inputField ? row.inputField : row.field;
                                return (
                                    <SelectInput
                                        label={row.label}
                                        options={row.options}
                                        handleChange={(value) => handleChange(value, field)}
                                    />
                                )
                            default: 
                                return (
                                    <div key={row.field} style={{ display:'flex', flexDirection: 'column', width: '100%' }}>
                                        <InputText
                                            id={row.field}
                                            label={row.label}
                                            handleChange={(value) => {
                                                handleChange(value, row.field)
                                            }}
                                        />
                                    </div>
                                )
                        }
                    })}
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ width: '100%' }}
                        onClick={() => {
                            handleSubmit(formValues)
                                .then(response => {
                                    handleToggle(response);
                                    setModal(false);
                                });
                        }}
                    >
                        Capturar
                    </Button>
                </div>
            </Modal>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <List className={classes.root}>
                    <Button onClick={() => setModal(true)} color="primary">Agregar</Button>
                    {items.map(item => (
                        <ListItem key={item.id} role={undefined} dense button onClick={() => handleToggle(item)} style={getStyles(item.id)}>
                            <ListItemText id={item.id} primary={item.name} />   
                        </ListItem> 
                    ))}
                </List>
                {activeItem && (
                    <div style={{ marginLeft: '1em' }}>
                        {panel.map(row => (
                            <Text title={row.label} subtitle={activeItem[row.field]} />
                        ))}
                    </div>
                )}
            </div>
        </>
    )
};

export default ListGeneric;