import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

export default function RadioButtonsGroup({ question, setNext }) {
    const [value, setValue] = React.useState(0);
    const handleChange = (event) => {
        question.answer = event.target.value;
        setValue(event.target.value);
        setNext(true);
    };

    return (
        <FormControl component="fieldset">
            <RadioGroup
                aria-label="choice"
                name="choice1"
                value={Number(value)}
                onChange={handleChange}
            >
                {question.surveyQuestionChoices.map(choice =>(
                    <div key={choice.id} style={{ textAlign: 'left', width: '100%' }}>
                        <FormControlLabel
                            key={choice.id}
                            value={choice.id}
                            control={<Radio />}
                            label={choice.name}
                        />
                        <p style={{ fontSize: '.8em', marginLeft: '2.5em' }}>{choice.description}</p>
                    </div>
                ))}
            </RadioGroup>
        </FormControl>
    );
}