import api from '../index';

class Surveys {
    static getSurveyClient(surveyId, branchId) {
        return new Promise((resolve, reject) => {
            api.get(`/surveys/${surveyId}/${branchId}`)
                .then(response => resolve(response.data))
                .catch(err => reject(err))
        });
    }

    static createSurvey(answers, customerId, branchId, surveyId) {
        return new Promise((resolve, reject) => {
            api.post('/surveys' , { answers, customerId, branchId, surveyId })
                .then(response => resolve(response.data))
                .catch(err => reject(err))
        })
    }

    static createCustomer(name, email, phone) {
        return new Promise((resolve, reject) => {
            api.post('/customers', { name, email, phone })
                .then(response => resolve(response.data))
                .catch(err => reject(err))
        })
    }

    static createSurveyCustomer({ answers, customer, branchId, luSurveyId }){
        return new Promise((resolve, reject) => {
            api.post('/surveys/survey-customer', {
                answers,
                customer,
                branchId,
                luSurveyId
            })
            .then(response => resolve(response.data))
            .catch(err => reject(err));
        });
    }

    static getSurveysPerformance() {
        return new Promise((resolve, reject) => {
            api.get('/surveys/performance')
                .then(response => resolve(response.data))
                .catch(err => reject(err))
        })
    }

    static getAnsweredSurveys() {
        return new Promise((resolve, reject) => {
            api.get('/surveys')
                .then(response => resolve(response.data))
                .catch(err => reject(err))
        });
    }

    static getAnsweredSurveyById(answeredSurveyId) {
        return new Promise((resolve, reject) => {
            api.get(`/surveys/${answeredSurveyId}`)
                .then(response => resolve(response.data))
                .catch(err => reject(err))
        });
    }

}

export default Surveys;