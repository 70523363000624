import { transactions as initialState } from '../config/initialState';
import Transactions from '../api/services/Transactions';

const SET_CATEGORIES = '[TRANSACTIONS] SET_CATEGORIES';
const SET_CREATE_TXN = '[TRANSACTIONS] SET_CREATE_TXN';
const SET_TXNS = '[TRANSACTIONS] SET_TXNS';

export const CHANNELS_CATALOG = Object.freeze({
    TRANSFER: 1,
    CASH: 2,
    REVOLVING_CREDIT: 3,
    DIRECT_CREDIT: 4,
    CHECK: 5,
    INSIDE_TRANSFER: 6,
});

export const setCategories = categories => ({ type: SET_CATEGORIES, categories });
export const setCreateTxn = created => ({ type: SET_CREATE_TXN, created });
export const setTxns = (txns, amounts) => ({ type: SET_TXNS, txns, amounts });
export const getCategories = () => dispatch => (
    new Promise((resolve, reject) => {
        Transactions.getCategories()
            .then(response => {
                dispatch(setCategories(response.data));
                resolve();
            }).catch(err => reject(err));
    })
);

export const createTxn = (
    account, concept, category, channel, type, subtotal, tax, total
) => dispatch => (
    new Promise((resolve, reject) => {
        Transactions.createTxn(account, concept, category, channel, type, subtotal, tax, total)
            .then(response => {
                dispatch(setCreateTxn(response.data));
                resolve(response.data);
            }).catch(err => reject(err));
    })
)

export const getTxns = (businessEntities, bankAccounts, type) => dispatch => (
    new Promise((resolve, reject) => {
        Transactions.getTxns(businessEntities, bankAccounts, type)
            .then(response => {
                dispatch(setTxns(response.data.transactions, response.data.amounts));
                resolve(response.data.transactions);
            }).catch(err => reject(err));
    })
)

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CATEGORIES:
            return {
                ...state,
                categories: action.categories
            };
        case SET_CREATE_TXN:
            return {
                ...state,
                created: action.created
            }
        case SET_TXNS:
            return {
                ...state,
                txns: action.txns,
                amounts: action.amounts
            }
        default: return state;
    }
};
