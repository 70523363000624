import React, { useEffect, useState } from 'react';
import {
    Paper,
    Typography
} from '@material-ui/core';
import { connect } from 'react-redux';
import { getAnsweredSurveyById, getAnsweredSurveys } from '../../../ducks/surveys.ducks';
import Header from '../../common/header/index.header';
import { prettyDate } from '../../../utils/dateFormatter';

const AnsweredSurveyDetail = ({
    match,
    getAnsweredSurveyByIdDetail,
    answeredSurvey,
    history
}) => {
    const [loading, setLoading] = useState(true);
    const { answeredSurveyId } = match.params;
    useEffect(() => {
        getAnsweredSurveyByIdDetail(answeredSurveyId)
            .then(response => {
                setLoading(false);
            })
    }, [answeredSurveyId]);

    return(
        <>
            <Header
                title="Detalle de encuesta"
                history={history}
                enableBack />
            <Paper style= {{ marginBottom: '1em', padding: '1em' }}>
                <Typography variant="h6">{answeredSurvey.luSurvey.name}</Typography>
                <Typography variant="h6">{prettyDate(answeredSurvey.createdDate)}</Typography>
            </Paper>

            <Paper style= {{ marginBottom: '1em', padding: '1em' }}>
                <Typography variant="h6">{answeredSurvey.customer.name}</Typography>
                <Typography variant="h6">{answeredSurvey.customer.phone}</Typography>
                <Typography variant="h6">{answeredSurvey.customer.email}</Typography>
            </Paper>

            <Paper style= {{ marginBottom: '1em', padding: '1em' }}>
                {answeredSurvey.surveyAnswers.map(answer => {
                    let fixedAnswer = answer.answer;
                    if (answer.surveyQuestion.questionTypeId === 2) {
                        const answerChoice = answer.surveyQuestion.surveyQuestionChoices.find(
                            ({ id }) => id == answer.answer
                        );
                        fixedAnswer = `${answerChoice.name} - ${answerChoice.description}`
                        console.log('fixedAnswer: ', fixedAnswer);
                    }
                    return (
                        <div key={answer.id}>
                            <Typography variant="h6" style={{fontWeight: '600' }}>{answer.surveyQuestion.question}</Typography>
                            <Typography variant="body1">{fixedAnswer}</Typography>
                        </div>
                    )
                })}
            </Paper>
        </>
    )
};


const mapStateToProps = ({ surveys }) => ({
    answeredSurvey: surveys.answeredSurvey,
});

const mapDispatchToProps = dispatch => ({
    getAnsweredSurveyByIdDetail:
        answeredSurveyId => dispatch(getAnsweredSurveyById(answeredSurveyId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AnsweredSurveyDetail);