import React, { useEffect } from 'react';
import { ArgumentScale, Animation } from '@devexpress/dx-react-chart';
import {
    Chart,
    ArgumentAxis,
    ValueAxis,
    AreaSeries,
    Title,
    Legend,
  } from '@devexpress/dx-react-chart-material-ui';
import {
  curveCatmullRom,
  curve,
  area
} from 'd3-shape';
import { scalePoint } from 'd3-scale';

const POSITIVE_TENDENCY = [
    { step: 1, value: 2 },
    { step: 2, value: 4 },
    { step: 2, value: 3 },
    { step: 3, value: 5 },
];

const NEGATIVE_TENDENCY = [
    { step: 1, value: 5 },
    { step: 2, value: 3 },
    { step: 3, value: 4 },
    { step: 4, value: 2 },
];

const Area = props => (
    <AreaSeries.Path
        {...props}
        path={area()
            .x(({ arg }) => arg)
            .y1(({ val }) => val)
            .y0(({ startVal }) => startVal)
            .curve(curveCatmullRom)}
    />
  );

const DummyLine = ({ tendency, color }) => {
    let tendencyChart = tendency ? POSITIVE_TENDENCY : NEGATIVE_TENDENCY;
    return (
        <Chart
              data={tendencyChart}
              height={100}
              width={100}
        >
            <ArgumentScale
                factory={scalePoint}
            />
            <ArgumentAxis
                showLabels={false}
                showGrid={false}
                showTicks={false}
                showLine={false}
            />
            <ValueAxis
                showGrid={false}
                showTicks={false}
                showLine={false}
                showLabels={false}
            />

            <AreaSeries
                name="Tendency" 
                valueField="value"
                argumentField="step"
                seriesComponent={Area}
                color={tendency ? "rgba(0,0,255,.6)" : "rgba(255,0,0,.6)"}
            />
            <Animation />
        </Chart>
    )
};

export default DummyLine;