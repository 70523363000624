import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TextField, Button } from '@material-ui/core';
import { login } from '../../ducks/users.ducks';
import history from '../../utils/history';

const Login = ({
    doLogin,
}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (event) => {
        console.log('here')
        event.preventDefault();
        console.log('here2')
        doLogin(email.trim(), password)
            .then(() => {
                history.push('/');
            }).catch(err => console.log('err: ',err));
    }

    return (
        <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5em'}}>
            <form onSubmit={handleSubmit} style={{ display:'flex', flex: 1, flexDirection: 'column'}}>
                <TextField
                    required
                    id="standard-required"
                    label="Usuario"
                    onChange={(event) => setEmail(event.target.value)}
                    style={{ marginBottom: '2em' }}
                />
                <TextField
                    id="standard-password-input"
                    label="Contraseña"
                    type="password"
                    autoComplete="current-password"
                    onChange={(event) => setPassword(event.target.value)}
                    style={{ marginBottom: '2em' }}
                />


                <Button type="submit" size="large" variant="contained" color="primary">
                    Iniciar sesión
                </Button>
            </form>
        </div>
        
    );
};

const mapDispatchToProps = dispatch => ({
    doLogin: (email, password) => dispatch(login(email,password)),
});

Login.propTypes = {
    doLogin: PropTypes.func,
}

export default connect(null, mapDispatchToProps)(Login);
