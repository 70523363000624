import React from 'react';
import { Typography } from '@material-ui/core';

const Text = ({ title, subtitle }) => (
    <div style={{ marginBottom: '1em' }}>
        <Typography variant="h6" component="h6">{title}</Typography>
        <Typography variant="subtitle1" color="textSecondary">{subtitle}</Typography>
    </div>
);

export default Text;