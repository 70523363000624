    import React from 'react';
    import { makeStyles } from '@material-ui/core/styles';
    import Modal from '@material-ui/core/Modal';

    function getModalStyle() {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const useStyles = makeStyles((theme) => ({
        paper: {
            position: 'absolute',
            width: '50%',
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
    }));

export default function SimpleModal({ open, handleClose, children}) {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={modalStyle} className={classes.paper}>
                    {children}
                </div>
            </Modal>
        </div>
    );
}
