import React from 'react';
import { Typography } from '@material-ui/core';
import {
    SentimentVeryDissatisfied
} from '@material-ui/icons';

const EmptyState =({ copy }) => {

    return (
        <div
            style={{
                width: '100%',
                marginTop: '10em',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Typography variant="h4" component="h5" color="textSecondary">
                {copy ? copy : 'No se tiene información con esas características'}
            </Typography>
            <SentimentVeryDissatisfied />
        </div>
    )
};

export default EmptyState;