import api from '../index';

class Goals {
    static checkCurrentMonth() {
        return new Promise((resolve, reject) => (
            api.get('/goals/current-month')
                .then(response => resolve(response.data))
                .catch(err => reject(err))
        ))
    }

    static create(branchId, targetDate, amount) {
        return new Promise((resolve, reject) => {
            api.post('/goals', {branchId, targetDate, amount })
                .then(response => resolve(response.data))
                .catch(err => reject(err))
        });
    }
}

export default Goals;