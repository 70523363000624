import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Paper, useScrollTrigger, Typography } from '@material-ui/core';
import { getSales, getGoalsPerformance } from '../../ducks/reports';
import { getMe } from '../../ducks/users.ducks';
import Header from '../common/header/index.header';
import Loading from '../common/loading';
import Sales from './charts/charts.sales';
import Performance from './performance.home';
import { prettyMoney } from '../../utils/helpers';

const Home = ({
    series,
    amounts,
    getSalesHome,
    me,
    getHomeMe,
    branches,
    differenceIdeal,
    overallCompletionPercentage,
    performanceGetGoalsPerformance
}) => {
    const [loading, setLoading] = useState(true);
    const [loadingPerformance, setLoadingPerformance] = useState(true);
    const [timeframe, setTimeframe] = useState('');

    useEffect(() => {
        setLoading(true);
        getHomeMe()
            .then(() => setLoading(false));
    }, []);

    
    useEffect(() => {
        setLoading(true);
        setLoadingPerformance(true);

        getSalesHome(timeframe)
            .then(() => setLoading(false))
            .catch(err => console.log('err comp chart: ',err));
        performanceGetGoalsPerformance()
            .then(() => setLoadingPerformance(false))
            .catch(err => console.log('err peformance: ',err));

    }, [timeframe]);

    const BOXES_AMOUNTS = [
        { label: 'Venta total', amount: prettyMoney(amounts.tot_dia) },
        { label: 'Tarjetas', amount: prettyMoney(amounts.tarjeta) },
        { label: 'Efectivo', amount: prettyMoney(amounts.efectivo) },
        { label: 'Porcentaje global', amount: `${prettyMoney(amounts.tot_dia)} (${differenceIdeal})` },
    ]
    if(loading) return <Loading />
    return (
        <div>
            <Header title={`¡Hola, ${me.name}!`} subtitle="Los montos son del mes actual" />
            <div style={{ marginBottom: '1em' }}>
                <Sales
                    series={series}
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    marginBottom: '2em'
                }}
            >
                {BOXES_AMOUNTS.map(box => (
                    <Paper style={{ marginBottom: '2em', minWidth: '30%', padding: '1em 2em' }} elevation={2}>
                        <div>
                            <Typography variant="h4" component="h5" gutterBottom>{box.label}</Typography>
                            <Typography variant="h4" component="h6" color="textSecondary">{box.amount}</Typography>
                        </div>
                    </Paper>
                ))}
                
                
            </div>
            <Performance
                branches={branches}
            />
        </div>
    );
};

const mapStateToProps = ({ users: { me }, reports: { sales, goalsPerformance } }) => ({
    series: sales.series,
    amounts: sales.amounts,
    me,
    differenceIdeal: goalsPerformance.differenceIdeal,
    branches: goalsPerformance.branches,
    overallCompletionPercentage: goalsPerformance.overallCompletionPercentage,
});

const mapDispatchToProps = dispatch => ({
    getSalesHome: (timeframe) => dispatch(getSales(timeframe)),
    getHomeMe: () => dispatch(getMe()),
    performanceGetGoalsPerformance: (startDate, endDate) => dispatch(getGoalsPerformance(startDate, endDate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
