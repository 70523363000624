import * as React from 'react';
import {
  Chart,
  PieSeries,
  Tooltip,
} from '@devexpress/dx-react-chart-material-ui';
import { ArgumentScale, Animation } from '@devexpress/dx-react-chart';
import { scalePoint } from 'd3-scale';
import { EventTracker } from '@devexpress/dx-react-chart';

const Pie = ({ chartData, name }) => {
    return (
        <Chart
            data={chartData}
            width={200}
            height={200}
        >

            <ArgumentScale
                factory={scalePoint}
            />
            <PieSeries
                valueField="total"
                argumentField="name"
                name={name}
            />
            <Animation />
            <Tooltip
            />
        </Chart>
    )
};

export default Pie;