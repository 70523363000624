import api from '../index';

class User {
    static login(email, password, type) {
        return new Promise((resolve, reject) => {
            api.post('/users/login-ecosystem', { email, password, type })
                .then(response => resolve(response.data))
                .catch(err => console.log('err: ', err));
        });
    }


    static getBranches() {
        return new Promise((resolve, reject) => {
            api.get('/branches')
                .then(response => resolve(response.data))
                .catch(err => reject(err));
        })
    }

    static getBusinessEntities() {
        return new Promise((resolve, reject) => {
            api.get('/business-entities')
                .then(response => resolve(response.data))
                .catch(err => reject(err));
        })
    }

    static getBankAccounts() {
        return new Promise((resolve, reject) => {
            api.get('/bank-accounts')
                .then(response => resolve(response.data))
                .catch(err => reject(err));
        })
    }

    static getPaymentMethods() {
        return new Promise((resolve, reject) => {
            api.get('/sales/payment-methods')
                .then(response => resolve(response.data))
                .catch(err => reject(err));
        })
    }

    static createBankAccount({ name, accountNumber, clientNumber, bankId, businessEntityId }) {
        return new Promise((resolve, reject) => {
            api.post('/bank-account', {
                name, accountNumber, clientNumber, bankId, businessEntityId
            })
                .then(response => resolve(response.data))
                .catch(err => reject(err));
        })
    }

    static getMe() {
        return new Promise((resolve, reject) => {
            api.get('/me')
                .then(response => resolve(response.data))
                .catch(err => reject(err));
        })
    }

    static getUsers() {
        return new Promise((resolve, reject) => {
            api.get('/users')
                .then(response => resolve(response.data))
                .catch(err => reject(err));
        })
    }

    static createBusinessEntity({ name }) {
        return new Promise((resolve, reject) => {
            api.post('/business-entity', { name })
                .then(response => resolve(response.data))
                .catch(err => reject(err));
        })
    }
    static createBranch({ name, businessEntityId }) {
        return new Promise((resolve, reject) => {
            api.post('/branch', { name, businessEntityId })
                .then(response => resolve(response.data))
                .catch(err => reject(err));
        })
    }

    static createUser({ name, email, phone, alias, branchId }) {
        return new Promise((resolve, reject) => {
            const companyId = 1;
            const password = 'mara2020'
            api.post('/users', { name, email, phone, alias, companyId, password, branchId })
                .then(response => resolve(response.data))
                .catch(err => reject(err));
        });
    }
}

export default User;
