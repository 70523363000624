import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  LineSeries,
  Legend,
  Tooltip
} from '@devexpress/dx-react-chart-material-ui';
import { EventTracker } from '@devexpress/dx-react-chart';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ArgumentScale, Animation } from '@devexpress/dx-react-chart';
import {
  curveCatmullRom,
  line,
} from 'd3-shape';
import { scalePoint } from 'd3-scale';
import { prettyMoney } from '../../../utils/helpers';

const Line = props => (
    <LineSeries.Path
        {...props}
        path={line()
            .x(({ arg }) => arg)
            .y(({ val }) => val)
            .curve(curveCatmullRom)}
    />
);

const titleStyles = {
    title: {
        textAlign: 'center',
        width: '100%',
        marginBottom: '10px',
    },
};

const Text = withStyles(titleStyles)((props) => {
    const { text, classes } = props;
    const [mainText, subText] = text.split('\\n');
    return (
        <div className={classes.title}>
            <Typography component="h3" variant="h5">
                {mainText}
            </Typography>
            <Typography variant="subtitle1">{subText}</Typography>
        </div>
    );
});

const legendStyles = () => ({
  root: {
    display: 'flex',
    margin: 'auto',
    flexDirection: 'row',
  },
});

const legendLabelStyles = theme => ({
  label: {
    marginBottom: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
});

const legendItemStyles = () => ({
  item: {
    flexDirection: 'column-reverse',
  },
});

const legendRootBase = ({ classes, ...restProps }) => (
  <Legend.Root {...restProps} className={classes.root} />
);
const legendLabelBase = ({ classes, ...restProps }) => (
  <Legend.Label className={classes.label} {...restProps} />
);
const legendItemBase = ({ classes, ...restProps }) => (
  <Legend.Item className={classes.item} {...restProps} />
);
const Root = withStyles(legendStyles, { name: 'LegendRoot' })(legendRootBase);
const Label = withStyles(legendLabelStyles, { name: 'LegendLabel' })(legendLabelBase);
const Item = withStyles(legendItemStyles, { name: 'LegendItem' })(legendItemBase);
const demoStyles = () => ({
    chart: {
        paddingRight: '30px',
    },
});

const DayGraph = ({ data, classes }) => {
    const [loading, setLoading] = useState(true);
    return (
        <div className="p-1 px-4 bg-white rounded-lg shadow-sm mb-3">
            <Chart
              data={data}
              className={classes.chart}
              height={300}
            >
                <ArgumentScale factory={scalePoint} />
                <ArgumentAxis
                  showLabels
                  showGrid={false}
                  showTicks={false}
                  showLine={false}
                />
                <ValueAxis showGrid={false} />

                <LineSeries
                    name="Semana actual"
                    valueField="cwAmt"
                    argumentField="cwDay"
                    seriesComponent={Line}
                />

                <LineSeries
                    name="Semana pasada"
                    valueField="lwAmt"
                    argumentField="cwDay"
                    seriesComponent={Line}
                />
                <Animation />
                <Legend position="bottom" rootComponent={Root} labelComponent={Label} />
                <EventTracker />
                <Tooltip
                  contentComponent={({ text, targetItem}) => {
                    return (
                      <>
                        <p className="text-base font-medium">{targetItem.point}</p>
                        <p className="text-base font-medium">{prettyMoney(text)}</p>
                      </>
                    )
                  }}
                />
            </Chart>
        </div>
    );
;}
export default withStyles(demoStyles, { name: 'DayGraph' })(DayGraph);