export const TRANSACTION_TYPES = [
    {name: 'Ingreso', id: 1},
    {name: 'Egreso', id: 2}
];

export const TRANSACTION_CHANNELS = [
    { name: 'Transferencia', id: 1 },
    { name: 'Efectivo', id: 2 },
    { name: 'Credito Revolvente', id: 3 },
    { name: 'Credito Directo', id: 4 },
    { name: 'Cheque', id: 5 },
    { name: 'Traspaso', id: 6 }
];

export const TRANSACTION_CATEGORIES = [
    { name: 'RENTA', id: 10 }, 
    { name: 'SERVICIO CFE', id: 11 }, 
    { name: 'SERVICIO TELMEX', id: 12 },
    { name: 'SERVICIO TELCEL', id: 13 }, 
    { name: 'SERVICIO AGUA', id: 14 }, 
    { name: 'TARJETA DE CREDITO', id: 15 }, 
    { name: 'CREDITO SIMPLE- BAJIO', id: 16 }, 
    { name: 'LDC REVOLVENTE-BAJIO', id: 17 }, 
    { name: 'CREDITO SIMPLE- SANT', id: 18 }, 
    { name: 'CREDITO CARROS', id: 19 }, 
    { name: 'CREDITO HIPOTECARIO', id: 20 }, 
    { name: 'INTERESES DE CREDITO', id: 21 }, 
    { name: 'NOMINA', id: 22 }, 
    { name: 'SIPARE (IMSS)', id: 23 }, 
    { name: 'IMPUESTOS S/NOMINA', id: 24 }, 
    { name: 'FONACOT', id: 25 }, 
    { name: 'INFONAVIT', id: 26 }, 
    { name: 'OUTSOURCING', id: 27 }, 
    { name: 'PRESTAMOS', id: 28 }, 
    { name: 'PAPELERIA', id: 29 }, 
    { name: 'LIMPIEZA', id: 30 }, 
    { name: 'GASTOS EN GENERAL', id: 31 }, 
    { name: 'PAQUETERIA', id: 32 }, 
    { name: 'COMISION BANCARIA', id: 33 }, 
    { name: 'CHEQUE', id: 34 }
];


