import React, { useState } from 'react';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import {
    Select,
    InputLabel,
    MenuItem,
    FormControl
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import SelectInput from '../common/inputs/select.input';

const DatesPicker = ({
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    branches = null,
    branchId,
    setBranchId,
}) => {
    return (
        <div className="flex mb-1 w-full">
            <MuiPickersUtilsProvider style={{ display: 'flex' }} utils={DateFnsUtils}>
                <div className="flex flex-row justify-between items-center w-full">
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd MMMM yyyy"
                        margin="normal"
                        id="date-picker-inline-start"
                        label="Fecha de inicio"
                        value={startDate}
                        onChange={(value) => setStartDate(value)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd MMMM yyyy"
                        margin="normal"
                        id="date-picker-inline-end"
                        label="Fecha de cierre"
                        value={endDate}
                        onChange={(value) => setEndDate(value)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                    <SelectInput
                        options={branches}
                        label="Sucursal"
                        handleChange={(value) => setBranchId(value)}
                        value={branchId}
                    />
                </div>
            </MuiPickersUtilsProvider>
        </div>
    )
};

export default DatesPicker;