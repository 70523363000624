import React from 'react';
import { TextField } from '@material-ui/core';

const TextInput = ({ label, handleChange, value, id, style }) => {
    return (
        <TextField
            id={id}
            label={label}
            variant="outlined"
            value={value}
            onChange={({ target }) => handleChange(target.value)}
            style={{ marginBottom: '2em', ...style}}
        />
    )
};

export default TextInput;