import { users as initialState } from '../config/initialState';
import User from '../api/services/User';
import api from '../api';

// ACTIONS
const SET_USER = '[USER] SET_USER';
const SET_BRANCHES = '[USER] SET_BRANCHES';
export const SET_ME = '[USER] SET_ME';
export const SET_NEW_BRANCH = '[USER] SET_NEW_BRANCH';
export const SET_NEW_BUSINESS_ENTITY = '[USER] SET_NEW_BUSINESS_ENTITY';
export const SET_BUSINESS_ENTITIES = '[USER] SET_BUSINESS_ENTITIES';
export const SET_BANK_ACCOUNTS = '[USER] SET_BANK_ACCOUNTS';
export const SET_USERS = '[USER] SET_USERS';
export const SET_NEW_USER = '[USER] SET_NEW_USER';
export const SET_PAYMENT_METHODS = '[USER] SET_PAYMENT_METHODS';


// SETTERS
const setNewUser = (name, email, phone, alias) => ({ type: SET_NEW_USER, name, email, phone, alias });
const setBranches = (branches) => ({ type: SET_BRANCHES, branches });

export const setNewBranch = (name, businessEntityId) => ({ type: SET_NEW_BRANCH , name, businessEntityId });
export const setMe = ({ name, email, phone, alias }) => ({ type: SET_ME, name, email, phone, alias });
export const setUsers = (users) => ({ type: SET_USERS, users });
export const setBusinessEntities =  (businesses, entitiesIds) => ({
    type: SET_BUSINESS_ENTITIES, businesses, entitiesIds
});
export const setBankAccounts = (accounts, accountsIds) => ({
    type: SET_BANK_ACCOUNTS, accounts, accountsIds
});
export const setPaymentMethods = (paymentMethods) => ({ type: SET_PAYMENT_METHODS, paymentMethods });

// GETTERS
export const login = (email, password, type = 'admin') => dispatch => (
    new Promise((resolve, reject) => {
        User.login(email, password, type)
            .then(({ data }) => {
                if(data.token) {
                    const { id, user } = data.user;
                    api.setAuthHeader(data.token);
                    api.setCurrentUser({ id, user });
                    return resolve(data);
                }
            }).catch(err => console.log('err ducks login: ',err));
    })
);

export const getBranches = () => dispatch => (
    new Promise((resolve, reject) => {
        User.getBranches()
            .then((response) => {
                dispatch(setBranches(response.data.branches));
                return resolve(response.data.branches);
            })
            .catch(err => console.log('err getting branches: ', err));
    })
);

export const getMe = () => dispatch => (
    new Promise((resolve, reject) => {
        User.getMe()
            .then((response) => {
                dispatch(setMe(response.data.me));
                return resolve(response.data);
            })
            .catch(err => console.error('Err me: ',err));
    })
);

export const getUsers = () => dispatch => (
    new Promise((resolve, reject) => {
        User.getUsers()
            .then((response) => {
                dispatch(setUsers(response.data.users));
                return resolve(response.data);
            })
            .catch(err => console.error('Err get users: ',err));
    })
);

export const getBusinessEntities = () => dispatch => (
    new Promise((resolve, reject) => {
        User.getBusinessEntities()
            .then(response => {
                const entitiesIds = response.data.businessEntities.map(entity => entity.id);  
                dispatch(setBusinessEntities(response.data.businessEntities, entitiesIds));
                resolve();
            }).catch(err => reject(err));
    })
);

export const getPaymentMethods = () => dispatch => (
    new Promise((resolve, reject) => {
        User.getPaymentMethods()
            .then(response => {
                dispatch(setPaymentMethods(response.data.paymentMethods));
                resolve(response.data.paymentMethods);
            }).catch(err => reject(err));
    })
);


export const createBranch = ({ name, businessEntityId }) => dispatch => (
    new Promise((resolve, reject) => {
        User.createBranch({ name, businessEntityId })
            .then(async (response) => {
                await dispatch(getBranches());
                return resolve(response.data.branch);
            })
            .catch(err => console.error('Err branch: ',err));
    })
)

export const createBusinessEntity = ({ name }) => dispatch =>   (
    new Promise((resolve, reject) => {
        User.createBusinessEntity({ name })
            .then(async (response) => {
                await dispatch(getBusinessEntities());
                // dispatch(setBusinessEntities(response.data));
                return resolve(response.data.businessEntity);
            })
            .catch(err => console.error('Err be: ',err));
    })
);

export const createUser = ({ name, email, phone, alias, branchId }) => dispatch => (
    new Promise((resolve, reject) => {
        User.createUser({ name, email, phone, alias, branchId })
            .then(async (response) => {
                await dispatch(getUsers()); //.then(() => resolve(response.data));
                // dispatch(setNewUser(response.data));
                return resolve(response.data.user);
            })
            .catch(err => console.error('Err creating user: ',err));
    })
)

export const getBankAccounts = () => dispatch => (
    new Promise((resolve, reject) => {
        User.getBankAccounts()
            .then(response => {
                const accountsIds = response.data.accounts.map(account => account.id);
                dispatch(setBankAccounts(response.data.accounts, accountsIds));
                resolve();
            }).catch(err => reject(err));
    })
);

export const createBankAccount = (name, accountNumber, clientNumber, bankId, businessEntityId) => dispatch => (
    new Promise((resolve, reject) => {
        User.createBankAccount(name, accountNumber, clientNumber, bankId, businessEntityId)
            .then(async (response) => {
                console.log('response:' ,response);
                await dispatch(getBankAccounts());
                return resolve(response.data.account);
            })
    })
)


export const loadSettings = () => dispatch => {
    return new Promise((resolve, reject) => {
        Promise.all([
            dispatch(getBankAccounts()),
            dispatch(getBusinessEntities()),
            dispatch(getPaymentMethods()),
            dispatch(getBranches()),
            dispatch(getUsers()),
            dispatch(getMe())
        ])
            .then((response) => {
                return resolve(true);
            })
            .catch(err => console.error('loading profile: ',err))
    })
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                email: action.email,
            }
        case SET_BRANCHES:
            return {
                ...state,
                branches: action.branches,
            }
        case SET_NEW_BUSINESS_ENTITY:
            return {
                ...state,
                businessEntities: action.businesses
            }
        case SET_ME: 
            return {
                ...state,
                me: {
                    email: action.email,
                    phone: action.phone,
                    name: action.name,
                    alias: action.alias
                }
            }
        case SET_BUSINESS_ENTITIES:
            return {
                ...state,
                businessEntities: action.businesses,
                entitiesIds: action.entitiesIds
            };
        case SET_BANK_ACCOUNTS:
            return {
                ...state,
                bankAccounts: action.accounts,
                accountsIds: action.accountsIds
            };
        case SET_PAYMENT_METHODS:
            return {
                ...state,
                paymentMethods: action.paymentMethods
            }
        case SET_USERS:
            return {
                ...state,
                employees: action.users
            };
        default: return state;
    }
}
