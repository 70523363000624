import moment from 'moment';

export const prettyDate = (date) => moment(date).format('DD MMMM, YYYY');
export const prettyTimestamp = (date) => moment(date).format('DD-MMM HH:mm');

export const prettyMonth = (date) => moment(date).format('MMMM YYYY');

export const fullDate = (date) => moment(date).format('YYYY-MM-DD HH:mm:ss');

export const startOf = (day, era) => moment(day).startOf(era);

export const endOf = (day, era) => moment(day).endOf(era);

export const today = () => moment().format('YYYY-MM-DD')
