import React, { useState, useEffect }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Form } from 'react-bootstrap';
// date range picker
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import Header from '../common/header/index.header';
import EmptyState from '../common/empty';
import Loading from '../common/loading';


import { getBranch } from '../../ducks/reports';
import { getBranches } from '../../ducks/users.ducks';
import { today, prettyDate, fullDate, startOf, endOf } from '../../utils/dateFormatter';
import { prettyMoney } from '../../utils/helpers';

const Daily = ({
    dates,
    getReportsBranch,
    getListBranches,
    branches,
    amounts
}) => {
    const [loading, setLoading] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState(0)
    const [startDate, setStartDate] = useState(fullDate(startOf(today(), 'month')));
    const [endDate, setEndDate] = useState(fullDate(endOf(today(), 'month')));
    
    //POPULATE BRANCHES SELECT
    useEffect(() => {
        getListBranches();
    }, [getListBranches]);

    useEffect(() => {
        // get orders
        setLoading(true);
        if (startDate !== '' && endDate !== '' && selectedBranch > 0) {
            const stdStartDate = fullDate(startOf(startDate, 'day'));
            const stdEndDate = fullDate(endOf(endDate, 'day'));
            getReportsBranch(stdStartDate, stdEndDate, selectedBranch)
                .then(() => setLoading(false));
        } else setLoading(false);
    }, [getReportsBranch, startDate, endDate, selectedBranch]);

    const handleDateChange = (date, type) => {
        let setDate = type === 'start' ? setStartDate : setEndDate;
        setDate(date);
    };

    const handleBranchSelect = (e) => {
        setSelectedBranch([e.target.value]);
    };

    if (loading) return <Loading />;
    return (
        <div>
            <Header
                title="Reporte por sucursal"
                subtitle="Revisa las ventas de tus sucursales por día"
            />
            <div
                style={{ marginBottom: '1em' }}
            >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justify="space-around" alignItems="center">
                            <FormControl variant="outlined">
                                <InputLabel id="demo-simple-select-outlined-label">Sucursal</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={selectedBranch}
                                    onChange={handleBranchSelect}
                                    label="Sucursal"
                                    style={{ minWidth: '15em'}}
                                >
                                    <MenuItem value={0}>
                                        <em>Ninguna</em>
                                    </MenuItem>
                                    {branches.map((branch) => (
                                        <MenuItem
                                            key={branch.id}
                                            selected={branch.id === selectedBranch}
                                            value={branch.id}
                                        >
                                            {branch.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="dd MMMM yyyy"
                                margin="normal"
                                id="date-picker-inline-start"
                                label="Fecha de inicio"
                                value={startDate}
                                onChange={(value) => handleDateChange(value, 'start')}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="dd MMMM yyyy"
                                margin="normal"
                                id="date-picker-inline-end"
                                label="Fecha de cierre"
                                value={endDate}
                                onChange={(value) => handleDateChange(value, 'end')}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>
            </div>
            {dates.length > 0 ? (
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th rowSpan={2}>Fecha</th>
                            <th colSpan={3}>Tarjetas</th>
                            <th colSpan={4}>Totales</th>
                        </tr>
                        <tr>
                            <th>Santander</th>
                            <th>BanBajio</th>
                            <th>Amex</th>
                            <th>Coppel</th>
                            <th>Dolares</th>
                            <th>Efectivo</th>
                            <th>Tarjetas</th>
                            <th>Corte</th>
                            <th>Venta día</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dates.map(branch => (
                            <tr key={branch.created_date}>
                                <td>{prettyDate(branch.created_date)}</td>
                                <td>{prettyMoney(branch.santander)}</td>
                                <td>{prettyMoney(branch.bbjio)}</td>
                                <td>{prettyMoney(branch.amex)}</td>
                                <td>{prettyMoney(branch.coppel)}</td>
                                <td>{prettyMoney(branch.dolares)}</td>
                                <td>{prettyMoney(branch.efectivo)}</td>
                                <td>{prettyMoney(branch.tarjeta)}</td>
                                <td>{prettyMoney(branch.corte)}</td>
                                <td>{prettyMoney(branch.tot_dia)}</td>
                            </tr>
                        ))}
                        <tr key={'amounts'}>
                                <td><strong>Totales</strong></td>
                                <td>{prettyMoney(amounts.santander)}</td>
                                <td>{prettyMoney(amounts.bbjio)}</td>
                                <td>{prettyMoney(amounts.amex)}</td>
                                <td>{prettyMoney(amounts.coppel)}</td>
                                <td>{prettyMoney(amounts.dolares)}</td>
                                <td>{prettyMoney(amounts.efectivo)}</td>
                                <td>{prettyMoney(amounts.tarjeta)}</td>
                                <td>{prettyMoney(amounts.corte)}</td>
                                <td>{prettyMoney(amounts.tot_dia)}</td>
                            </tr>
                    </tbody>
                </Table>
            ): (
                <EmptyState />
            )}
        </div>
    );
};

const mapStateToProps = ({ users, reports: { branch } }) => ({
    dates: branch.dates,
    branches: users.branches,
    amounts: branch.amounts
});

const mapDispatchToProps = dispatch => ({
    getReportsBranch: (stDt, endDt, branchId) => dispatch(getBranch(
        stDt, endDt, branchId,
    )),
    getListBranches: () => dispatch(getBranches()),
});

Daily.propTypes = {
    getReportsBranch: PropTypes.func,
    dates: PropTypes.arrayOf(Object),
    branchId: PropTypes.number,
    branchName: PropTypes.string,
    amounts: PropTypes.shape({})
}

export default connect(mapStateToProps, mapDispatchToProps)(Daily);
