  import React, { useState } from 'react';
  import clsx from 'clsx';
  import { makeStyles, useTheme } from '@material-ui/core/styles';
  import {
    ListItemIcon,
    ListItemText,
    ListItem,
    Drawer,
    CssBaseline,
    List,
    Divider,
    IconButton
  } from '@material-ui/core';
  import {
    ChevronLeft,
    ChevronRight
  } from '@material-ui/icons';
  import { Link } from 'react-router-dom';
  import Bar from './bar.template';
  import navbar from './navbar';


  const drawerWidth = 240;

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  }));

  export default function PersistentDrawerLeft({ children }) {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
      setOpen(true);
    };

    const handleDrawerClose = () => {
      setOpen(false);
    };

    return (
      <div className={classes.root}>
        <CssBaseline />
        <Bar
          handleDrawerOpen={handleDrawerOpen}
          classes={classes}
          open={open}
        />
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
          </div>
          <List>
            {navbar.map(nav => (
              <ListItem
                component={Link}
                to={nav.url}
                button
                key={nav.key}
              >
                    <ListItemIcon>
                        <nav.icon />
                    </ListItemIcon>
                    <ListItemText primary={nav.label} />
                    {nav.new && <span style={{ fontSize: '.8em', color: 'blue' }}>NEW</span>}
                </ListItem>
            ))}
          </List>
          <Divider />
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
            {children}
        </main>
      </div>
    );
  }
