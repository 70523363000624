import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  margin: {
    height: theme.spacing(3),
  },
}));

const marks = [
  {
    value: 30,
    label: '30',
  },
  {
    value: 60,
    label: '60',
  },
  {
    value: 80,
    label: '80',
  },

  {
    value: 100,
    label: '100',
  },
];

function valuetext(value) {
  return `${value}  `;
}

export default function DiscreteSlider({ question, setNext }) {
  // const classes = useStyles();
  const handleChange = (e, value) => {
    question.answer = value;
    setNext(true);
  }
  return (
    <div className={{ width: '100%' }}>
      <Slider
        style={{ width: '100%' }}
        defaultValue={80}
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider-always"
        step={10}
        marks={marks}
        valueLabelDisplay="on"
        onChange={handleChange}
      />
    </div>
  );
}
