
export const prettyMoney = (value) => {
    const places = 2;
    const thousand = ',';
    const number = Number(value);
    const negative = number < 0 ? '-' : '';
    const formattedNumber=
      parseInt(Math.abs(+number || 0).toFixed(places), 10) + '';
    const numberLength = formattedNumber.length;
    const markerPoint = numberLength > 3 ? numberLength % 3 : 0;
    return (
      '$' +
      negative +
      (markerPoint ? formattedNumber.substr(0, markerPoint) + thousand : '') +
      formattedNumber
        .substr(markerPoint)
        .replace(/(\d{3})(?=\d)/g, '$1' + thousand) +
      (places
        ? '.' +
          Math.abs(Number(number) - Number(formattedNumber))
            .toFixed(places)
            .slice(2)
        : '')
    );
  };


export const prettyPercentage = (value) => {
  const places = 2;
  const thousand = ',';
  const number = Number(value);
  const negative = number < 0 ? '-' : '';
  const formattedNumber=
    parseInt(Math.abs(+number || 0).toFixed(places), 10) + '';
  const numberLength = formattedNumber.length;
  const markerPoint = numberLength > 3 ? numberLength % 3 : 0;
  return (
    negative +
    (markerPoint ? formattedNumber.substr(0, markerPoint) + thousand : '') +
    formattedNumber
      .substr(markerPoint)
      .replace(/(\d{3})(?=\d)/g, '$1' + thousand) +
    (places
      ? '.' +
        Math.abs(Number(number) - Number(formattedNumber))
          .toFixed(places)
          .slice(2)
      : '')
     + '%'
  );
};

export const prettyNumber = (value) => {
  const places = 2;
  const thousand = ',';
  const number = Number(value);
  const negative = number < 0 ? '-' : '';
  const formattedNumber=
    parseInt(Math.abs(+number || 0).toFixed(places), 10) + '';
  const numberLength = formattedNumber.length;
  const markerPoint = numberLength > 3 ? numberLength % 3 : 0;
  return (
    negative +
    (markerPoint ? formattedNumber.substr(0, markerPoint) + thousand : '') +
    formattedNumber
      .substr(markerPoint)
      .replace(/(\d{3})(?=\d)/g, '$1' + thousand) +
    (places
      ? '.' +
        Math.abs(Number(number) - Number(formattedNumber))
          .toFixed(places)
          .slice(2)
      : '')
  );
};
  

export const prettyDecimal = number => parseFloat(number).toFixed(2);

// VALIDATORS
export const validateEmail = (email) => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};

export const validatePhone = phone => {
  const isNumber = validateNumeric(phone);
  const length = phone.toString().length;
  if (isNumber && (length === 10)) return true;
  return false;
}

export const validateEmpty = value => {
  if (value.length > 0) return true;
  return false;
}

export const validateNumeric = number => {
  const regex =  /^\d+$/;
  return regex.test(number);
}