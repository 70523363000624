import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper
} from '@material-ui/core';
import { prettyNumber } from '../../../utils/helpers';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

const QuestionsPerformance = ({
    questionsPerformance,
}) => {
    const classes = useStyles();
    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell><strong>Pregunta</strong></TableCell>
                        <TableCell><strong>Encuesta</strong></TableCell>
                        <TableCell><strong>Respuestas</strong></TableCell>
                        <TableCell><strong>Resultado (Avg)</strong></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {questionsPerformance.map((question) => (
                        <TableRow key={question.questionId}>
                            <TableCell component="th" scope="row">
                                {question.surveyQuestion.question}
                            </TableCell>
                            <TableCell>{question.surveyQuestion.luSurvey.name}</TableCell>
                            <TableCell>{question.answersCnt}</TableCell>
                            <TableCell>{prettyNumber(question.avgValue)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
};

export default QuestionsPerformance;