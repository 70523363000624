import React from 'react';
import { Fab } from '@material-ui/core';
import { Add } from '@material-ui/icons';

const FabIcon = ({ url, color, onClick, position  }) => {
    return (
        <div
            style={{
                position: position ? position : 'fixed',
                bottom: '3em',
                right: '3em'
            }}
        >
            <Fab
                color={color ? color : "primary"}
                aria-label="add-label"
                onClick={onClick}
            >
                <Add />
            </Fab>
        </div>
    )
};

export default FabIcon;