import React from 'react';
import { TextField } from '@material-ui/core';
import {
    DESCRIPTION,
    EMAIL,
    QUICK_ANSWER,
    NUMBER,
    PHONE_NUMBER
} from '../surveys.constants';

import { validateEmail, validatePhone, validateEmpty, validateNumeric } from '../../../utils/helpers';

const QuickAnswer = ({ question, setNext, rows, placeholder, type }) => {
    const description = question && question.description ? question.description : '';
    const handleChange = (e) => {
        if (e.target.value.length > 0) {
            question.answer = e.target.value;
            let validated = false;
            switch  (type) {
                case DESCRIPTION:
                    validated = validateEmpty(e.target.value);
                    break;
                case EMAIL:
                    validated = validateEmail(e.target.value);
                    break;
                case QUICK_ANSWER:
                    validated = validateEmpty(e.target.value);
                    break;
                case NUMBER:
                    validated = validateNumeric(e.target.value);
                    break;
                case PHONE_NUMBER:
                    validated = validatePhone(e.target.value);
                    break;
            }
            if (validated) {
                setNext(true);
            } else setNext(false);

        } else setNext(false);   
    }


    const getProps = type => {
        switch (type) {
            case DESCRIPTION:
                return {
                    inputType: 'text',
                    id: 'outlined-multiline-static',
                    multiline: true,
                    inputProps: {
                        
                    }
                };
            case EMAIL:
                return {
                    inputType: 'email',
                    id: 'outlined-multiline-static',
                    multiline: false,
                    inputProps: {
                        
                    }
                };
            case QUICK_ANSWER:
                return {
                    inputType: 'text',
                    id: 'outlined-multiline-static',
                    multiline: false,
                    inputProps: {
                        
                    }
                };
            case NUMBER:
                return {
                    inputType: 'number',
                    id: 'standard-number',
                    multiline: false,
                    inputProps: {
                        
                    }
                };
            case PHONE_NUMBER:
                return {
                    inputType: 'number',
                    id: 'standard-number',
                    multiline: false,
                    inputProps: {
                        maxLength: 10
                    }
                };
            default: return 'text';
        }
    }
    const { inputType, id, multiline, inputProps } = getProps(type);
    return (
        <TextField
            style={{ width: '100%' }}
            id={id}
            label="Escribe aquí"
            placeholder={placeholder}
            type={inputType}
            multiline={multiline}
            onChange= {handleChange}
            rows={rows}
            required={true}
            inputProps={inputProps}
        />
    )
};

export default QuickAnswer;