import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Fade, Slide, Button
} from '@material-ui/core';
import Number from './number.client';
import Multiple from './multiple.client';
import QuickAnswer from './quickAnswer.client';
import Loading from '../../common/loading';

import {
    DESCRIPTION,
    EMAIL,
    MULTIPLE,
    QUICK_ANSWER,
    NUMBER,
    PHONE_NUMBER
} from '../surveys.constants';
import { getSurveyClient, createSurveyCustomer } from '../../../ducks/surveys.ducks';



const SurveysClient = ({
    getSurvey,
    postSurvey,
    survey,
    match,
    history
}) => {
    const [loading, setLoading] = useState(true);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [activeQuestion, setActiveQuestion] = useState(0);
    const [checked, setChecked] = useState(true);
    const [nextEnabled, setNextEnabled] = useState(false);
    const [nextLabel, setNextLabel] = useState('Aceptar');
    const { surveyId, branchId } = match.params;

    useEffect(() => {
        getSurvey(surveyId, branchId)
            .then(() => setLoading(false))
            .catch(err => console.log('surveys list ',err))
    }, [getSurvey]);

    const handleSubmit = () => {
        setLoadingSubmit(true);
        postSurvey(survey.questions, branchId, surveyId)
            .then(({ data }) => {
                setLoadingSubmit(false);
                history.push('/survey/thanks')
            }).catch(err => {
                setLoadingSubmit(false);
                console.log('err: ', err)
            });
    }

    const handleNext = () => {
        const current = activeQuestion + 1;
        const isLast = (current + 1) >= survey.questions.length;
        if (survey.questions.length > (current)) {
            setChecked(!checked);
            setNextEnabled(false);
            setTimeout(() => {
                if (isLast) setNextLabel('Completar');
                setActiveQuestion(current);
                setChecked(true);
            }, 300);
        } else {
            handleSubmit();
        }
    }


    const handleQuestionType = (activeQuestion) => {
        const active = survey.questions[activeQuestion];
        if (active) {   
            switch (active.questionTypeId) {
                case DESCRIPTION: return <QuickAnswer type={DESCRIPTION} rows={4} setNext={setNextEnabled} question={active} />
                case NUMBER: return <Number type={NUMBER} setNext={setNextEnabled} question={active} />
                case MULTIPLE: return <Multiple setNext={setNextEnabled} question={active} />;
                case QUICK_ANSWER: return <QuickAnswer type={QUICK_ANSWER} rows={1} setNext={setNextEnabled} question={active} />;
                case PHONE_NUMBER: return <QuickAnswer type={PHONE_NUMBER} setNext={setNextEnabled} question={active} />;
                case EMAIL: return <QuickAnswer type={EMAIL} placeholder="jose@grupomara.mx" rows={1} setNext={setNextEnabled} question={active} />;
                default:
                    return <h1>question default</h1>
            }
        }
    }

    if (loading) return <Loading />
    return (
        <div
            style={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}
        >
            <div
                style={{
                    height: '100%',
                    width: '90%',
                    margin: 'auto',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <Slide direction={checked ? "up" : "down"} in={checked} mountOnEnter unmountOnExit>
                    <div style={{ width: '100%' }}>
                        <h1 style={{ marginBottom: '1em' }}>
                            {survey.questions[activeQuestion] && survey.questions[activeQuestion].question}
                        </h1>
                        <div style={{ marginBottom: '2em' }}>
                            {handleQuestionType(activeQuestion)}
                        </div>
                        <Fade in={nextEnabled}>
                        <Button
                            disabled={loadingSubmit}
                            style={{ width: '100%' }}
                            onClick={handleNext}
                            variant="contained"
                            color="primary"
                        >
                            {nextLabel}
                        </Button>
                </Fade>
                    </div>
                </Slide>
            </div>
        </div>
    )
};

const mapStateToProps = ({ surveys }) => ({
    survey: surveys.surveyClient
});

const mapDispatchToProps = dispatch => ({
    getSurvey: (surveyId, branchId) => dispatch(getSurveyClient(surveyId, branchId)),
    postSurvey: (answers, branchId, surveyId) => dispatch(createSurveyCustomer(answers, branchId, surveyId)),
});

SurveysClient.propTypes = {
    getSurveyClient: PropTypes.func,
    survey: PropTypes.shape({})
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveysClient);